.footerStart {
	z-index: 99;
	font-weight: 500;
	position: relative;
	background: #ffffff;
	box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);



	.footerWidget {
		padding: 40px 0px;

		.logo {
			// width: 230px;
			height: 35px;
			object-fit: contain;
			filter: drop-shadow(2px 4px 6px black);
			margin-bottom: 1.5rem;
			display: inline-flex;

			img {
				width: 100%;
				height: auto;
				object-fit: contain;
			}
		}

		p {
			color: #000;
			font-size: 13px;
			margin: 0 0 30px;
		}

		.footerSocial {
			position: relative;
			display: block;
			margin-top: 1.5rem;

			ul {
				margin: 0;
				padding: 0;
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;
				list-style: none;

				li {
					list-style: none;
					display: inline-block;
					position: relative;
					margin-right: 0px;
					margin-top: 15px;

					a {
						width: 38px;
						height: 38px;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						border-radius: 0.2rem;
						background: rgba(255, 255, 255, 0.1);
						font-size: 15px !important;
						transition: all ease 0.4s;
						color: #ff0000;
					}
				}
			}
		}

		h4 {
			margin-bottom: 20px;
			font-size: 15px;
			color: #000;
			font-weight: 600;
		}

		.footerMenu {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				margin-top: 15px;
				display: block;

				a {
					color: #6e8497;
					font-size: 13px;
					text-decoration: none;
					transition: .3s;

					&:hover {
						color: #022f5d;
					}
				}
			}
		}


	}

	.bottomFooter {
		margin-bottom: 0 !important;
		text-align: center;
		color: #022f5d;
		font-size: 13px;
	}

	.endFooter {

		a {
			color: #6e8497;
			font-size: 14px;
			text-decoration: none;
		}
	}
}
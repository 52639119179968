@import "variables.module.scss";
@import "fonts.module.scss";

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  font-family: Verdana, sans-serif !important;
}

// .font {
//   font-family: 'Verdana, sans-serif';
// }

.title {
  margin-bottom: 2rem;

  h2 {
    font-size: 35px;
    line-height: 1.4;
    font-weight: 700;
    color: #05264e;
  }
}

.spb {
  padding: 60px 0px;
}

.paddingBottom {
  padding-bottom: 60px;
}

.header_height {
  // height: 87px;
  height: 72px;
}

.formBox {
  margin-bottom: 20px;

  .labelTitle {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 600;
  }

  :global(.form-control) {
    height: 38px;
    font-size: 15px;
    box-shadow: none;
    border: 1px solid #bbc2c6;
    border-radius: 4px;
    padding: 10px 12px;
    background-color: #f4f4f4;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #b3aeae;
    }
  }

  .file_inputContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .custom_fileInput {
      display: inline-flex;
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #007ac3;
      color: white;
      text-align: center;
      font-size: 24px;
      cursor: pointer;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }

      .plusIcon {
        display: inline-flex;
      }
    }

    .imgs_preview {
      position: relative;

      .imgshow {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #e9e5e5;
      }

      .removeButton {
        position: absolute;
        top: -6px;
        right: -5px;
        background-color: #007ac3;
        border-radius: 50%;
        border: none;
        color: white;
        width: 20px;
        height: 20px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;

  &:hover input~.checkmark {
    background-color: #fff;
    border: 1px solid var(--WebsiteMain);
  }

  input {
    opacity: 0;
    margin-right: 10px;
    position: absolute;

    &:checked~.checkmark {
      border: 1px solid var(--WebsiteMain);
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid var(--WebsiteMain);

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid var(--WebsiteMain);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.btnsGroup {
  .fillBtn {
    background-color: #008cd3;
    color: #fff;
    padding: 10px 25px;
    font-size: 17px;

    &:hover {
      background-color: #008cd3;
      color: #fff;
    }
  }
}

.add_removeForm {
  text-align: end;
  margin-top: 5px;

  button {
    background-color: #008cd3;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 33px;
    max-width: 33px;
    min-height: 34px;
  }
}

:global(.quill) {
  :global(.ql-toolbar) {
    &:global(.ql-snow) {
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #ccc;
    }
  }

  :global(.ql-container) {
    &:global(.ql-snow) {
      border: 1px solid #ccc;
      border-radius: 0px 0px 4px 4px;
      background: #f4f4f4;
    }

    :global(.ql-editor) {
      min-height: 100px;
    }
  }
}

.create_register {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.6s;

  :global(.owl-carousel) {
    :global(.owl-item) {
      :global(.item) {
        :global(img) {
          // height: 20.5625vw;
          height: 281px;
          object-fit: cover;
        }
      }
    }

    :global(.owl-dots) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      bottom: 0;

      :global(.owl-dot) {
        :global(span) {
          background: #5c5c5c;
        }

        &:global(.active) {
          :global(span) {
            background: #fff;
          }
        }
      }
    }
  }

  :global(.swiper) {
    :global(.swiper-wrapper) {
      :global(.swiper-slide) {
        :global(.item) {
          :global(img) {
            width: 100%;
            height: 281px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .main_heading {
    transition: 0.4s;
    text-align: center;
    position: relative;
    z-index: 9;

    h1 {
      font-weight: 700;
      margin-top: 50px;
    }

    &.slider_content {
      transform: translate(0%, -250%);
      margin-top: -105px;
    }
  }
}

.suggest_AdsCarousel {
  :global(.swiper) {

    :global(.swiper-wrapper) {
      :global(.swiper-slide) {
        margin-right: 14px !important;
        height: 130px;

        :global(.item) {
          text-align: center;

          :global(img) {
            width: 100%;
            // max-width: 100%;
            height: 102px;
            object-fit: cover;
          }
        }
      }
    }

    :global(.swiper-pagination) {
      bottom: 0px;
    }
  }
}

.language_select {
  :global(.dropdown) {

    :global(.dropdown-toggle) {
      padding: 5px 12px;
      background-color: #ffffff;
      border: none;
      border-radius: 16px;
      color: #007ac3;
      box-shadow: none;

      :global(svg) {
        font-size: 20px;
        margin-right: 2px;
      }

      &::before {
        position: relative;
        right: -3px;
      }

    }
  }
}

.phone_CountryLibrary{
  :global(.flag-dropdown ){
    border-radius: 0px !important;
  }
  :global(.form-control){
    &:focus{
      box-shadow: none;
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 575px) {
  .create_register {
    :global(.owl-carousel) {
      :global(.owl-item) {
        :global(.item) {
          :global(img) {
            height: 90px;
          }
        }
      }

      :global(.owl-dots) {

        :global(.owl-dot) {
          :global(span) {
            width: 5px;
            height: 5px;
            margin: 4px 2px;
            opacity: 0.4;
            background: #4e4e4e;
          }

          &:global(.active) {
            :global(span) {
              opacity: .6;
              // background: #007aff;
            }
          }
        }
      }
    }

    :global(.swiper) {
      :global(.swiper-wrapper) {
        :global(.swiper-slide) {
          :global(.item) {
            :global(img) {
              height: 90px;
            }
          }
        }
      }

      :global(.swiper-pagination) {
        bottom: 2px;

        :global(.swiper-pagination-bullet) {
          width: 5px;
          height: 5px;
          opacity: 0.2;
          margin: 0px 2px;
        }
      }
    }

    .main_heading {

      h1 {
        font-size: 23px;
        margin-top: 12px;
      }

      &.slider_content {
        transform: translate(0%, -125%);
        margin-top: -44px;
      }
    }
  }
}
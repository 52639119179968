.offer_main_box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  display: block;
  color: #000;
  text-decoration: none;
  position: relative;

  &:hover {
    box-shadow: rgb(69 185 255 / 20%) 0px 2px 8px 0px;

    .offer_imgBox {
      img {
        transform: scale(1.1);
      }
    }
  }

  .offer_imgBox {
    overflow: hidden;

    img {
      width: 100%;
      height: 268px;
      object-fit: cover;
      transform: scale(1);
      transition: 0.3s;
    }
  }

  .contentArea {
    padding: 22px 0;
    padding-right: 20px;

    h3 {
      font-size: 22px;
      font-weight: 600;
      margin: 14px 0px 20px;
    }

    .headLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      h4 {
        font-size: 17px;
        font-weight: 700;
        color: #FF0000;
        margin-bottom: 0px;
      }

      .rating {
        display: flex;
        font-weight: 700;
        align-items: baseline;

        :global(svg) {
          color: #FFEA00;
          margin-right: 4px;
          font-size: 12px;
        }
      }
    }

    .timing_heart {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        display: flex;
        align-items: center;
        font-size: 14px;

        :global(svg) {
          color: #008cd3;
          margin-right: 8px;
        }
      }

      .heart {
        color: #008cd3;
        font-size: 17px;
        cursor: pointer;
      }
    }

    p {
      padding-bottom: 14px;
      margin-bottom: 2px;
      border-bottom: 1px solid #979797;

    }

    .location_views {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      .location {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 15px;

        :global(svg) {
          color: #FFEA00;
        }
      }

      .views {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;

        :global(svg) {
          color: #FFEA00;
        }
      }
    }
  }

  .mark_sold {
    position: absolute;
    right: 12px;
    top: 10px;
    display: flex;
    gap: 4px;
    z-index: 99;

    :global(.btn) {
      background-color: #ff0000;
      color: #fff;
      padding: 4px 4px;
      display: flex
  ;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      gap: 3px;

      :global(svg) {
        position: relative;
        // top: -2px;
        font-size: 16px;
      }
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {

  .offer_main_box {
    .contentArea {
      padding: 10px 16px;
    }
  }

}
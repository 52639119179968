.filterSidebar {
  .filterTitle {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.select_dropdown_box {
  label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  :global(.form-select) {
    line-height: 30px;
    box-shadow: none;
  }
}

.cardBox {
  border: 1px solid #dfdfdf;
  padding: 15px 15px;
  border-radius: 6px;
  margin-bottom: 20px;

  :global(.css-1n6sfyn-MenuList),
  :global(.css-qr46ko) {
    padding: 4px 8px !important;

    div {
      padding: 3px 0px;
      display: flex;
      align-items: center;
      gap: 9px;
    }

    input {
      width: 18px;
      min-height: 18px;
      cursor: pointer;
    }

    .checkgap {
      margin-bottom: 3px;
      width: 100%;
      padding-top: 2px;
      padding-bottom: 2px;

      &:hover {
        background-color: rgb(246, 246, 246);
      }
    }
  }
}

:global(.search_relatedBtn) {
  position: relative;

  :global(.form-control) {
    padding: 12px 10px;
    // padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #b2b2b2;

    &:focus {
      box-shadow: none;
    }
  }

  .municipality_filterList {
    position: absolute;
    top: 100%;
    background-color: #fff;
    z-index: 99;
    border: 1px solid #dcdcdc;
    width: 100%;
    margin-top: 2px;
    border-radius: 10px;
    max-height: 225px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ul {
      padding: 4px 0;
      margin: 0;

      li {
        padding: 2px 11px;
        font-size: 15px;
        margin: 0;
        cursor: pointer;
        line-height: normal;

        &:hover {
          background-color: #eaeaea;
        }
      }
    }
  }

  :global(.btn) {
    padding: 3px 10px;
    width: 44px;
    height: 50px;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #008cd3;
    color: #fff;
    border: transparent;

    :global(svg) {
      width: 24px;
      height: 21px;
    }

    &:active {
      background-color: #008cd3;
      color: #fff;
    }
  }
}

.municipality_listingShow {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 14px;

    li {
      display: flex;
      justify-content: space-between;
      font-size: 15px;

      .municipality_names {
        font-size: 16px;
      }

      button {
        border: none;
        background-color: transparent;
        font-size: 16px;
        color: #000;

        &:hover {
          color: rgb(55, 21, 206);
        }
      }
    }
  }

  .clearBtn {
    text-align: end;

    button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: #0066a5;
      font-size: 15px;
      font-weight: 600;
      margin-top: 6px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.rating {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 0px;

  li {
    color: #d4d4d4;
    display: block;
    margin-bottom: 15px;

    label {
      display: flex;
      align-items: center;
      gap: 2px;

      .stars {
        font-size: 20px;
        margin-left: 5px;
        display: flex;
        gap: 3px;
      }
    }

    &.active {
      color: #f4b60deb;
    }
  }
}

.listMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 0px;

  &.scrollList {
    overflow: hidden;
    height: 270px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(214, 214, 214);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(0, 0, 0);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }
  }

  li {
    display: block;
    margin-bottom: 15px;

    label {
      line-height: 21px;
      display: flex;
      gap: 2px;

      .name {
        margin-top: 2px;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}

.rangeFilter {
  margin-top: 60px;

  :global(.MuiSlider-root) {
    :global(.MuiSlider-rail) {
      background-color: #008cd3;
    }

    :global(.MuiSlider-track) {
      background-color: #008cd3;
      border: 1px solid #008cd3;
    }

    :global(.MuiSlider-thumb) {
      background: #008cd3;

      &::before,
      &::after {
        display: none;
      }

      :global(.MuiSlider-valueLabelOpen) {
        background-color: #008cd3;
      }
    }
  }

  :global(.MuiSlider-valueLabelOpen) {
    position: relative;
  }
}

.checkBox_customize {
  padding-bottom: 6px;


  :global(.form-check-input) {
    height: 1.4rem;
    font-size: 19px;

    &:focus{
      box-shadow: none;
      // border-color: #dee2e6;
    }
  }

  :global(form) {
    display: flex;
    align-items: center;
    padding-left: 0px;

    label{
      font-weight: 400;
      padding-right: 17px;
      margin-bottom: 0px;
    }
  }

  :global(.form-switch) {
    :global(.form-check-label) {
      // font-size: 20px;
      // width: 2.2rem;
    }

    :global(.form-check-label) {
      font-size: 14px;
      margin-top: 5px;
    }
  }
}

@media (max-width: 767px) {
  .rangeFilter {
    padding: 0px 25px;
  }

  .filterSidebar {
    .filterTitle {
      font-size: 19px;
    }
  }

}
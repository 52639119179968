.pageSpace{
  padding-top: 30px;
  padding-bottom: 30px;

  .onboardingBox{
    overflow: hidden;
    background: #ffffff;
    padding: 24px;
    border-radius: 30px;
    border: 0px;
    box-shadow: 0px 0px 10px -2px rgb(224 224 224 / 81%);

    .onboardingHeading{
      text-align: center;

      h1{
        font-size: 32px !important;
        color: var(--Color);
        margin-bottom: 12px;
        font-weight: 700;
      }

      p{
        font-size: 17px;
        color: #5d6f7d;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }

    .formStart{

      .otpField{
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 22px;
        justify-content: center;

        input{
          height: 60px !important;
          width: 60px !important;
          font-size: 16px;
          box-shadow: none;
          border: 1px solid #c3c4c6;
          border-radius: 4px;
          padding: 4px;
          text-align: center;

          &:focus{
            outline: none;
            box-shadow: none;
            border-color: #008cd3;
          }
        }

        div{
          gap: 40px;
        }

      }

      .inputIcon{

          svg{
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(0%, -50%);
            color: var(--WebsiteMain);
          }
        }

      .onboardingButton{
        margin-bottom: 20px;

        :global(.btn){
          padding: 1em 1.5em;
          height: 62px;
          background-color: #008cd3;
          border: 1px solid #008cd3;
          color: #fff;
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
      }

      p{
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #5d6f7d;

        a{
          color: #008cd3;
          font-size: 15px;
          font-weight: 500;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;

          svg{
            font-size: 30px;
          }
        }
      }
    }
  }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 480px) {
  .formStart{
    .otpField{
     gap: 0px !important; 

     div{
      gap: 14px !important;
     }
    }
  }
}
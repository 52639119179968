.pageSpace {
  padding-top: 40px;
  padding-bottom: 40px;
}
.filter_topSlider {
  
}
.slideMain_box {
  display: flex;
  justify-content: space-between;

  .left {
    width: 100%;

    :global(.nav){
      :global(.nav-item){
        min-width: 20%;
      }
    }
    .boxes {
      text-align: center;
      display: block;
      color: #716d6d;
      text-decoration: none;
      padding: 0px;
      padding-bottom: 10px;
      transition: color 0.3s ease;
      position: relative;

      &:hover {
        &::before {
          background-color: #716d6d !important;
        }
      }
      &:global(.active) {
        
        &:hover {
          &::before {
            background-color: #000000 !important;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 3px;
        background-color: #000;
        bottom: 2px;
        left: 25%;
        border-radius: 4px;
        transform: scaleX(0);
        transform-origin: bottom center;
        transition: transform 0.3s ease;
      }

      &:hover,
      &:global(.active) {
        color: #000000 !important;

        &::before {
          transform: scaleX(1);
        }
      }

      svg {
        margin-bottom: 10px;
        font-size: 30px;
      }

      p {
        margin-bottom: 0px;
      }
    }

    :global(.owl-theme) {
      position: relative;

      :global(.owl-nav) {
        :global(.owl-prev),
        :global(.owl-next) {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -150%);
          border: 1px solid #000;
          border-radius: 50%;
          display: flex;
          padding: 3px !important;
        }

        :global(.owl-prev) {
          left: 0;
        }

        :global(.owl-next) {
          right: -30px;
        }

        :global(.disabled) {
          display: none !important;
        }
      }
    }
  }
}

.favouriteSection {
  .title {
    margin-bottom: 30px;

    h1 {
      text-align: center;
      font-size: 32px;
      color: #000;
      margin-bottom: 0px;
      font-weight: 700;
    }
  }
}


.notUploadedBox {
  text-align: center;
  padding: 50px 0px;

  span {
    background: #000;
    color: #fff;
    padding: 10px 23px;
    border-radius: 6px;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    gap: 11px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    :global(svg) {
      font-size: 30px;
    }
  }
}


@media (max-width: 572px) {

  .notUploadedBox {
    padding: 35px 0px;

    span {
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      gap: 11px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      :global(svg) {
        font-size: 26px;
      }
    }
  }
}
.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 180px 0px;
  padding-bottom: 115px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.searchBox {
  padding: 40px 0px;

  .sarcheHeading {
    margin-bottom: 24px;

    h3 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #000;
    }
  }

  .sarcheBar {
    :global(.form-control) {
      height: 50px;
      width: 100%;
      color: #000;
      font-weight: 500;
      border-radius: 5px;
      border: 1px solid #c8c8c8;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: #008cd3;
      }
    }

    :global(.btn) {
      background-color: #008cd3;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid #008cd3;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.tenderBox {
  .tanderTable {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 20px;

      &.tableHeading {
        background-color: #008cd3;
        border: 1px solid #008cd3;
        border-radius: 5px;

        li {
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
          border-left: 1px solid #fff;

          &:first-child {
            border-left: none;
          }
        }
      }

      li {
        width: 25%;
      }
    }
  }
}

:global(.tender_tag) {
  transform: rotate(180deg);
  font-size: 20px;

  &:global(.progressClr) {
    color: #60b900;
  }

  &:global(.lessdayClr) {
    color: #ff9600;
  }

  &:global(.epxiredClr) {
    color: #ff1701;
  }

  &:global(.unspecifiedClr) {
    color: #a4a4a4;
  }
}

.tender_tableSec {

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #005085;
    border-radius: 10px;
  }
}

.tender_tableSec {
  :global(.table) {
    th {
      font-size: 19px;
    }

    td {
      font-size: 14px;

      :global(a) {
        text-decoration: none;
        color: #000;

        p {
          font-weight: 600;
          margin-bottom: 5px;
          min-width: 170px;
        }

        span {
          font-weight: 600;
          color: #555555;
        }
      }
    }

    .heart {
      button {
        border: none;
        background: none;

        :global(svg) {
          display: block;
          fill: rgb(0 0 0 / 52%);
          height: 20px;
          width: 20px;
          stroke: #fff !important;
          strokewidth: 40px;
          overflow: visible;

          // &:hover {
          //   fill: rgb(255 0 0 / 70%);
          // }
        }

        &.active {
          :global(svg) {
            fill: rgb(255 0 0 / 100%);
          }
        }
      }
    }
  }
}

.post_attachBtn {
  text-align: end;
  margin-bottom: 24px;

  :global(.btn) {
    background-color: #ff0000 !important;
    color: #fff !important;
    // min-width: 165px;
    // margin-left: 5px;
    width: 100%;
    line-height: 38px;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    transition: 0.2s;
    font-weight: 500;

    &:hover {
      background-color: #f30505 !important;
      color: #fff !important;
    }

    &:active {
      border-color: transparent;
    }

    // span {
    //   &:nth-child(1) {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 3px;
    //     background: linear-gradient(to right, #171618, #008cd3);
    //     animation: animate1 2s linear infinite;
    //   }

    //   @keyframes animate1 {
    //     0% {
    //       transform: translateX(-100%);
    //     }

    //     100% {
    //       transform: translateX(100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(2) {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 3px;
    //     background: linear-gradient(to bottom, #171618, #008cd3);
    //     animation: animate2 2s linear infinite;
    //     animation-delay: 1s;
    //   }

    //   @keyframes animate2 {
    //     0% {
    //       transform: translateY(-100%);
    //     }

    //     100% {
    //       transform: translateY(100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(3) {
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 3px;
    //     background: linear-gradient(to left, #171618, #008cd3);
    //     animation: animate3 2s linear infinite;
    //   }

    //   @keyframes animate3 {
    //     0% {
    //       transform: translateX(100%);
    //     }

    //     100% {
    //       transform: translateX(-100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(4) {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 3px;
    //     background: linear-gradient(to top, #171618, #008cd3);
    //     animation: animate4 2s linear infinite;
    //     animation-delay: 1s;
    //   }

    //   @keyframes animate4 {
    //     0% {
    //       transform: translateY(100%);
    //     }

    //     100% {
    //       transform: translateY(-100%);
    //     }
    //   }
    // }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {}

@media (max-width: 767px) {
  .main_heading {
    h1 {
      font-size: 24px;
    }
  }

  // .create_register {
  //   padding: 90px 0px;
  //   padding-bottom: 20px;
  //   background-position: right;
  // }

  .searchBox {
    padding-top: 18px;
    .sarcheHeading {
      margin-bottom: 8px;
      margin-top: 8px;

      h3 {
        font-size: 22px;
      }
    }

    .sarcheBar {
      :global(.form-control) {
        margin-bottom: 10px;
      }

    }

    .smallDeviceBtn {
      display: flex;
      justify-content: center;

      :global(.smallbtn) {
        width: auto;
        min-width: 130px;
      }
    }
  }

  .post_attachBtn {
    :global(.btn) {
      min-width: 135px;
      line-height: 35px;
    }
  }

  .tenderBox {
    .tanderTable {
      ul {
        flex-wrap: wrap;

        &.tableHeading {
          li {
            width: 100%;
            border: none;
            text-align: left;
          }
        }
      }
    }
  }
}


.user_Chat {
    background: #ebeaea;

    .main_heading {
        h2 {
            font-size: 30px;
            font-weight: 600;
        }
    }

    .chat_box {
        :global(.card) {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            
            :global(.card-header) {
                background-color: #008cd3;
                color: #fff;
                font-weight: 500;
                position: relative;

                .back_arrow {
                    position: absolute;
                    left: 12px;

                    :global(svg) {
                        color: #fff;
                        font-size: 22px;
                    }
                }

            }

            :global(.card-body) {
                position: relative;
                overflow: hidden;
                height: calc(100vh - 300px);
                overflow-x: hidden;
                overflow-y: auto;

                .leftSide_chat {
                    :first-child {
                        background-color: #dcdcdc;
                        color: #000;
                    }
                }

                .rightSide_chat {
                    :first-child {
                        background-color: #f0f8ff;
                        color: #000;
                    }
                }
            }
        }

    }
}

.userImage {
    width: 14px;
    height: 14px;
    margin-left: 12px;
    position: relative;

    .editIcon {
        position: absolute;
        cursor: pointer;

        .edit {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;

            :global(svg) {
                font-size: 18px;
            }

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
}

.faq_sec {
    background-color: #ebeaea;
    border-top: 1px solid #9b9797;

    .main_heading {
        h2 {
            font-size: 35px;
            font-weight: 600;
        }
    }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
.main_box {
  position: relative;

  .Topbox_Reatives {
    text-decoration: none;
    color: #000000;
    box-shadow: 0px 0px 5px 0px #0000001a;
    border-radius: 13px 13px 0px 0px;

    .slider_heartDiv {
      position: relative;

      .imgBox {
        :global(.owl-carousel) {
          :global(.owl-stage-outer) {
            border-radius: 13px 13px 0px 0px !important;

            :global(.owl-item) {
              :global(.item) {
                position: relative;

                .ads_imgBox {
                  img {
                    width: 100%;
                    height: 240px;
                    object-fit: cover;
                    // border-radius: 13px 13px 0px 0px;
                  }
                }
              }
            }
          }

          &:hover {
            :global(.owl-nav) {
              opacity: 1;
            }
          }

          :global(.owl-nav) {
            margin-top: 0px;
            opacity: 0;
            transition: 0.3s;

            :global(.owl-prev),
            :global(.owl-next) {
              position: absolute;
              top: 50%;
              transform: translate(0%, -50%);
              background-color: transparent;
              border: none;

              :global(svg) {
                background-color: #ffffffde;
                color: #000000;
                border-radius: 50%;
                width: 28px;
                padding: 6px;
                height: 28px;
              }
            }

            :global(.owl-prev) {
              left: 0;
            }

            :global(.owl-next) {
              right: 0;
            }
          }

          :global(.owl-dots) {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);

            :global(.owl-dot) {
              &:global(.active) {
                span {
                  background-color: #ffffff !important;
                }
              }

              :global(span) {
                width: 7px;
                height: 7px;
                margin: 5px 4px;
              }
            }
          }
        }

        .actionarea {
          text-align: center;
          justify-content: space-between;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          margin-top: 100px;
          z-index: 1;

          :global(.btn) {
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            border: none;
            padding: 0px 0px;
            font-size: 15px;
            width: 30px;
            /* Example width */
            height: 30px;
            /* Example height */
            border-radius: 50%;
            /* Making it a circle */
            margin: 2px;

            &.actionBtn {
              background: #d6d6d6;
              color: #fff;
              top: 0;
            }
          }
        }

        .actionarea2 {
          text-align: center;
          justify-content: space-between;
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 100px;
          z-index: 1;

          :global(.btn) {
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            border: none;
            padding: 0px 0px;
            font-size: 15px;
            width: 30px;
            /* Example width */
            height: 30px;
            /* Example height */
            border-radius: 50%;
            /* Making it a circle */
            margin: 2px;

            &.actionBtn {
              background: #d6d6d6;
              color: #fff;
              top: 0;
            }
          }
        }

        .left_btnSlider {
          display: none;
          transition: 0.5s;
        }
      }

      .heart {
        position: absolute;
        top: 8px;
        z-index: 2;
        right: 4px;

        button {
          border: none;
          background: none;

          :global(svg) {
            display: block;
            fill: rgb(255 0 0 / 28%);
            height: 20px;
            width: 20px;
            stroke: #fff !important;
            strokewidth: 40px;
            overflow: visible;

            &:hover {
              fill: rgb(255 0 0 / 70%);
            }
          }

          &.active {
            :global(svg) {
              fill: rgb(255 0 0 / 100%);
            }
          }
        }
      }

      .soldProductLabel {
        position: absolute;
        top: 7px;
        left: 7px;
        z-index: 9;
        background: red;
        color: #fff;
        padding: 2px 12px;
        font-size: 12px;
        border-radius: 10px 10px 10px 10px;
      }
    }

    .contentArea {
      padding: 14px 12px;
      padding-bottom: 14px;

      h3 {
        font-size: 18px;
        font-weight: 600;
        margin: 7px 0px 15px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .headLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h4 {
          font-size: 15px;
          font-weight: 700;
          margin-bottom: 0px;
        }

        .rating {
          display: flex;
          font-weight: 700;
          align-items: baseline;

          :global(svg) {
            color: #ffea00;
            margin-right: 4px;
            font-size: 11px;
          }
        }
      }

      .timing_heart {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time {
          display: flex;
          align-items: center;
          font-size: 14px;

          :global(svg) {
            color: #008cd3;
            margin-right: 6px;
          }
        }
      }

      p {
        // padding-bottom: 14px;
        margin-bottom: 2px;
        border-bottom: 0.12rem solid;
        border-image: linear-gradient(90deg, #009ad7, #c93837, #ffe854) 1;
        font-size: 14px;
        min-height: 86px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .location_views {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        .location {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 13px;
          line-height: normal;

          :global(svg) {
            min-width: 13px;
          }
        }

        .views {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 13px;

          :global(svg) {
            min-width: 13px;
          }
        }
      }
    }
  }

  .bottomBox_absolute {
    position: absolute;
    top: 11px;
    right: 20px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #ff0000;
    }
  }

  &:hover .left_btnSlider {
    display: block !important;
  }
}
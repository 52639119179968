// .create_register {
//   background-image: url(../../../assets/images/banner-bg-main-new.png);
//   width: 100%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 190px 0px 100px 0px;
//   position: relative;
//   background-position: center;

//   &::before {
//     display: block;
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 0.2;
//     background: #fff;
//   }

//   .main_heading {
//     background: #ffffff4a;
//     padding: 30px 20px;
//     border-radius: 10px;
//     position: relative;
//     z-index: 1;

//     h2 {
//       font-size: 30px;
//       text-align: center;
//       font-weight: 600;
//       margin-bottom: 20px;
//     }

//     .searchFormBody {
//       display: flex;

//       .box {
//         width: 100%;
//         padding: 0px 6px;

//         &.radiusForm {
//           display: flex;
//           border-radius: 5px;

//           :global(.form-control) {
//             border-radius: 6px 0px 0px 6px;
//           }

//           :global(.form-select) {
//             border-radius: 0px 6px 6px 0px;
//             width: 200px;
//           }
//         }

//         :global(.form-control) {
//           padding: 12px 15px;

//           &:focus {
//             box-shadow: none;
//           }
//         }

//         :global(.form-select) {
//           padding: 12px 15px;

//           &:focus {
//             box-shadow: none;
//           }
//         }
//       }

//       .searchBtn {
//         background: #008cd3;
//         color: #fff;
//         min-width: 130px;
//         margin-left: 5px;
//       }
//     }
//   }
// }

.create_register {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.6s;

  // :global(.owl-carousel) {
  //   :global(.owl-item) {
  //     :global(.item) {
  //       :global(img) {
  //         height: 281px;
  //         object-fit: cover;
  //       }
  //     }
  //   }

  //   :global(.owl-dots) {
  //     position: absolute;
  //     left: 50%;
  //     transform: translate(-50%, 0%);
  //     bottom: 0;

  //     :global(.owl-dot) {
  //       :global(span) {
  //         background: #5c5c5c;
  //       }

  //       &:global(.active) {
  //         :global(span) {
  //           background: #fff;
  //         }
  //       }
  //     }
  //   }
  // }
  :global(.swiper) {
    :global(.swiper-wrapper) {
      :global(.swiper-slide) {
        :global(.item) {
          :global(img) {
            width: 100%;
            height: 281px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .main_heading {
    transition: 0.4s;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: 22px;
    // background: #ffffff4a;
    padding: 30px 20px;
    border-radius: 10px;
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 80%;

    h2 {
      font-weight: 700;
    }

    &.slider_content {
      transform: translate(0%, -75%);
      margin-top: -105px;
    }
  }
}

.searchFormBody {
  display: flex;

  .box {
    width: 100%;
    padding: 0px 6px;

    &.radiusForm {
      display: flex;
      border-radius: 5px;

      :global(.form-control) {
        border-radius: 6px 0px 0px 6px;
      }

      :global(.form-select) {
        border-radius: 0px 6px 6px 0px;
        width: 200px;
      }
    }

    :global(.form-control) {
      padding: 12px 15px;

      &:focus {
        box-shadow: none;
      }
    }

    :global(.form-select) {
      padding: 12px 15px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .searchBtn {
    background: #008cd3;
    color: #fff;
    min-width: 130px;
    margin-left: 5px;
  }
}

.filterSidebar {
  .filterTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.cardBox {
  border: 1px solid #dfdfdf;
  padding: 15px 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.rating {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 0px;

  li {
    color: #d4d4d4;
    display: block;
    margin-bottom: 15px;

    label {
      display: flex;
      align-items: center;

      .stars {
        margin-top: 2px;
        font-size: 20px;
        margin-left: 5px;
        display: flex;
        gap: 3px;
      }
    }

    &.active {
      color: #f4b60deb;
    }
  }
}

.listMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 0px;

  li {
    display: block;
    margin-bottom: 15px;

    label {
      line-height: 21px;

      .name {
        margin-top: 2px;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}

.listingSection {
  padding: 50px 0px;
  padding-top: 14px;
}

.listingPostion {
  position: relative;

  .starMark {
    position: absolute;
    right: 15px;
    top: 18px;

    a {
      padding: 0px 0px;
      margin-top: -12px;
      margin-right: 6px;

      svg {
        color: #f30505;
      }
    }
  }

  .heart {
    button {
      border: none;
      background: none;
      padding: 0;

      :global(svg) {
        display: block;
        fill: rgb(0 0 0 / 52%);
        height: 20px;
        width: 20px;
        stroke: #fff !important;
        strokewidth: 40px;
        overflow: visible;

        &:hover {
          fill: rgb(255 0 0 / 70%);
        }
      }

      &.active {
        :global(svg) {
          fill: rgb(255 0 0 / 100%);
        }
      }
    }
  }
}

.listingCard {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  // cursor: pointer;
  position: relative;
  display: block;
  text-decoration: none;
  color: #000;

  strong {
    background-color: transparent !important;
  }

  img {
    max-width: 100%;
    max-height: 220px;
    object-fit: contain;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 8px 0px 8px 0px;
  }

  small {
    font-size: 12px;
    line-height: 20px;
    display: block;
    padding-right: 20px;
  }

  p {
    font-size: 14px;
    color: #5f5f5f;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 104px;
    margin-bottom: 11px;
  }

  .readMore {
    font-size: 14px;
    text-decoration: none;
    color: #008cd3;
  }

  // .starMark {
  //   position: absolute;
  //   right: 15px;
  //   top: 18px;
  // }

  &:hover {
    background: #e4f6ff;
  }

  // .heart {

  //   button {
  //     border: none;
  //     background: none;
  //     padding: 0;

  //     :global(svg) {
  //       display: block;
  //       fill: rgb(0 0 0 / 52%);
  //       height: 20px;
  //       width: 20px;
  //       stroke: #fff !important;
  //       strokeWidth: 40px;
  //       overflow: visible;

  //       &:hover {
  //         fill: rgb(255 0 0 / 70%);
  //       }
  //     }

  //     &.active {
  //       :global(svg) {
  //         fill: rgb(255 0 0 / 100%);
  //       }
  //     }

  //   }
  // }
}

.post_attachBtn {
  text-align: end;
  margin-bottom: 24px;

  :global(.btn) {
    background-color: #ff0000 !important;
    color: #fff !important;
    min-width: 165px;
    margin-left: 5px;
    line-height: 38px;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    transition: 0.2s;
    font-weight: 500;

    &:hover {
      background-color: #f30505 !important;
      color: #fff !important;
    }

    &:active {
      border-color: transparent;
    }

    // span {
    //   &:nth-child(1) {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 3px;
    //     background: linear-gradient(to right, #171618, #008cd3);
    //     animation: animate1 2s linear infinite;
    //   }

    //   @keyframes animate1 {
    //     0% {
    //       transform: translateX(-100%);
    //     }

    //     100% {
    //       transform: translateX(100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(2) {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 3px;
    //     background: linear-gradient(to bottom, #171618, #008cd3);
    //     animation: animate2 2s linear infinite;
    //     animation-delay: 1s;
    //   }

    //   @keyframes animate2 {
    //     0% {
    //       transform: translateY(-100%);
    //     }

    //     100% {
    //       transform: translateY(100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(3) {
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 3px;
    //     background: linear-gradient(to left, #171618, #008cd3);
    //     animation: animate3 2s linear infinite;
    //   }

    //   @keyframes animate3 {
    //     0% {
    //       transform: translateX(100%);
    //     }

    //     100% {
    //       transform: translateX(-100%);
    //     }
    //   }
    // }

    // span {
    //   &:nth-child(4) {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 3px;
    //     background: linear-gradient(to top, #171618, #008cd3);
    //     animation: animate4 2s linear infinite;
    //     animation-delay: 1s;
    //   }

    //   @keyframes animate4 {
    //     0% {
    //       transform: translateY(100%);
    //     }

    //     100% {
    //       transform: translateY(-100%);
    //     }
    //   }
    // }
  }

  .filter_mobile {
    display: none;

    :global(.filter_btn) {
      border: 1px solid #474747;
      background-color: #fafafa;
      margin-top: 6px;
      border-radius: 7px;
      padding: 6px 10px;
      line-height: 30px;
      color: #000;

      :global(svg) {
        transform: rotate(90deg);
        font-size: 26px;
      }
    }
  }
}

.searchFormBodyMobile_Block {
  display: none;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .create_register {
    // padding: 90px 0px 20px 0px;

    .main_heading {
      margin-top: -10px;
      padding: 0;

      h2 {
        font-size: 26px;
        margin-top: 46px;
        margin-bottom: 10px;
      }

      &.slider_content {
        transform: translate(0%, -385%);
        margin-top: -97px;
      }
    }
  }

  .searchFormBody {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

    .searchBtn {
      margin-left: 11px;
    }
  }

  .searchFormBodyMobile_none {
    display: none;
  }

  .searchFormBodyMobile_Block {
    display: block;
    padding-top: 23px;
  }

  .post_attachBtn {
    display: flex;
    justify-content: end;
    gap: 18px;
    position: relative;

    :global(.btn) {
      min-width: 135px;
      line-height: 30px;
      margin-top: 6px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .filter_mobile {
      display: block;
    }
  }

  .filterHide_onMobile {
    display: none;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 575px) {
  .create_register {
    :global(.owl-carousel) {
      :global(.owl-item) {
        :global(.item) {
          :global(img) {
            height: 90px;
          }
        }
      }
    }

    :global(.swiper) {
      :global(.swiper-wrapper) {
        :global(.swiper-slide) {
          :global(.item) {
            :global(img) {
              height: 90px;
            }
          }
        }
      }
      :global(.swiper-pagination) {
        bottom: 2px;

        :global(.swiper-pagination-bullet) {
          width: 5px;
          height: 5px;
          opacity: 0.2;
          margin: 0px 2px;
        }
      }
    }

    .main_heading {
      h2 {
        font-size: 18px;
        margin-top: 24px;
        margin-bottom: 0px;
      }

      &.slider_content {
        transform: translate(0%, -185%);
        margin-top: -47px;
      }
    }
  }
}

.topBanner{
    background-color: #e5f3ff;
    padding: 70px 0px 180px;


    h1{
        color: #3f3f3f;
        font-size: 44px;
        margin-bottom: 15px;
        font-weight: 700;
    }

    p{
        font-size: 15px;
        color: #5d6f7d;
        margin-bottom: 0px;
        font-weight: 400;
    }
}

.contactPage{
    margin-top: -120px;
    padding-bottom: 80px;

    .contactBox{
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0 0.5rem 1rem rgba(24,142,241,.20) !important;
        -webkit-box-shadow: 0 0.5rem 1rem rgba(24,142,241,.20) !important;
        border-radius: 8px;
        padding: 1.5rem;

        .getInTouch{
            background-color: #008cd3;
            border-radius: 8px;
            height: 100%;
            padding: 1.5rem;

            h2{
                font-weight: 700;
                margin-bottom: 22px;
                font-size: 24px;
                color: #fff;
            }

            p{
                color: #fff;
                opacity: .75;
                font-size: 15px;
                font-weight: 400;
            }

            ul{
                padding: 0px;
                list-style: none;

                li{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 12px 0px;

                    svg{
                        color: #fff;
                        font-size: 18px;
                    }

                    a{
                        color: #fff;
                        font-size: 15px;
                        font-weight: 400;
                        text-decoration: none;
                    }
                }
            }
        }

        .formStart{

            :global(.form-control){
              height: 58px;
              font-size: 15px;
              box-shadow: none;
              border: 1px solid #e7edf1;
              border-radius: 8px;
              padding: 10px 10px 10px 10px;
      
              &:focus{
                outline: none;
                box-shadow: none;
              }
            }

            .textArea{
                min-height: 160px;
            }
      
            span{
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 10px 0px;
              font-size: 16px;
              font-weight: 500;
            }
      
            .onboardingButton{
              margin-bottom: 20px;
      
              :global(.btn){
                padding: 1em 1.5em;
                height: 62px;
                background-color: #008cd3;
                border: 1px solid #008cd3;
                color: #fff;
                width: 212px;
                font-size: 16px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
      
                &:focus{
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {

  .topBanner{
    padding: 40px 0px 120px;


    h1{
        font-size: 32px;
    }
}

.contactPage{
    margin-top: -70px;
    padding-bottom: 40px;

    .contactBox{

        .getInTouch{
            margin-bottom: 20px;
            height: auto;
        }

        .formStart{

          .onboardingButton{

            :global(.btn){
              width: 100%;
            }
          }
        }
    }
}

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;
}

.edit_prodfileSec {

  .profile_main {
    padding: 28px 25px;
    box-shadow: rgb(69 69 69 / 8%) 0px 0px 12px;
    border-radius: 9px;

    h2 {
      font-weight: 700;
      margin-bottom: 22px;
    }
  }
}

.userImage {
  width: 80px;
  height: 80px;
  margin-bottom: 18px;
  position: relative;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
  }

  .editIcon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;

    .edit {
      width: 24px;
      height: 24px;
      background: #12477a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      color: #fff;
      position: relative;
      cursor: pointer;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}

.reviewSection {
  padding-bottom: 60px;

  .contentReview {
    margin-bottom: 22px;

    h2 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #000;
    }
  }

  .cardBox {
    display: flex;
    gap: 22px;
    border: 1px solid #dee2e6;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
    }

    ul {
      list-style: none;
      margin: 0px;
      padding-left: 0px;
      margin-top: 6px;

      li {
        margin-left: 4px;
        color: #FFEA00;
      }
    }

    .userDetails {

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        color: #5d6f7d;
        font-size: 15px;
        margin-bottom: 0px;
        font-weight: 400;
      }
    }
  }
}

.number_otpBox {
  .otpVerify {
    position: absolute;
    right: 0px;
    top: 0px;
    border: 1px solid transparent;
    background-color: #008cd3;
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    font-size: 13px;
    padding: 8px 9px;
  }
}

@media (max-width: 767px) {

  .pageSpace {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
// .create_register {
//     background-image: url(../../../assets/images/banner-bg-main-new.png);
//     width: 100%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     padding: 180px 0px;
//     padding-bottom: 115px;
//     position: relative;
//     background-position: center;

//     &::before {
//         display: block;
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         opacity: 0.2;
//         background: #fff;
//     }

//     .main_heading {
//         position: relative;
//         text-align: center;

//         h1 {
//             font-weight: 700;
//         }
//     }
// }

// .create_register {
//     width: 100%;
//     position: relative;
//     overflow: hidden;
//     transition: .6s;


//     :global(.owl-carousel) {
//         :global(.owl-item) {
//             :global(.item) {
//                 :global(img) {
//                     // height: 20.5625vw;
//                     height: 281px;
//                     object-fit: cover;
//                 }
//             }
//         }

//         :global(.owl-dots) {
//             position: absolute;
//             left: 50%;
//             transform: translate(-50%, 0%);
//             bottom: 0;

//             :global(.owl-dot) {
//                 :global(span) {
//                     background: #5c5c5c;
//                 }

//                 &:global(.active) {
//                     :global(span) {
//                         background: #fff;
//                     }
//                 }
//             }
//         }
//     }

//     .main_heading {
//         position: relative;
//         z-index: 9;
//         transform: translate(0%, -250%);
//         transition: 0.4s;
//         text-align: center;
//         margin-top: -105px;

//         h1 {
//             font-weight: 700;
//             margin-top: 50px;
//         }
//     }
// }

.select_dropdownMain {

    .select_dropdown_box {
        label {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 4px;
        }

        :global(.form-select) {
            line-height: 30px;
            box-shadow: none;
        }
    }

}

.rentList_Sides {
    .main_box {
        position: relative;

        .Topbox_Reatives {
            text-decoration: none;
            color: #000000;

            .imgBox {
                img {
                    height: 271px;
                    object-fit: cover;
                }
            }

            .contentArea {
                padding: 12px;
                padding-bottom: 14px;
                box-shadow: 0px 0px 5px 0px #0000001A;

                .headLine {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 8px;

                    h4 {
                        font-size: 22px;
                    }

                    .rating {
                        display: flex;
                        font-weight: 700;
                        align-items: baseline;

                        :global(svg) {
                            color: #FFEA00;
                            margin-right: 4px;
                        }
                    }
                }

                .timing_prices {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .time {
                        display: flex;
                        align-items: center;

                        :global(svg) {
                            color: #008cd3;
                            margin-right: 8px;
                        }
                    }

                    .price {
                        color: #FF0000;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }
        }

        .bottomBox_absolute {
            position: absolute;
            top: 11px;
            right: 20px;
            color: #fff;
            cursor: pointer;
            transition: .3s;

            &:hover {
                color: #FF0000;
            }
        }
    }
}

.paginationBox {
    :global(.pagination) {
        justify-content: center;
    }
}

.post_attachBtn {
    text-align: end;
    margin-bottom: 0px;

    :global(.btn) {
        background-color: #ff0000 !important;
        color: #fff !important;
        min-width: 165px;
        margin-left: 5px;
        line-height: 38px;
        position: relative;
        font-weight: 600;
        overflow: hidden;
        transition: .2s;
        font-weight: 500;

        &:hover {
            background-color: #f30505 !important;
            color: #fff !important;
        }

        &:active {
            border-color: transparent;
        }

        // span {
        //   &:nth-child(1) {
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     width: 100%;
        //     height: 3px;
        //     background: linear-gradient(to right, #171618, #008cd3);
        //     animation: animate1 2s linear infinite;
        //   }

        //   @keyframes animate1 {
        //     0% {
        //       transform: translateX(-100%);
        //     }

        //     100% {
        //       transform: translateX(100%);
        //     }
        //   }
        // }

        // span {
        //   &:nth-child(2) {
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     height: 100%;
        //     width: 3px;
        //     background: linear-gradient(to bottom, #171618, #008cd3);
        //     animation: animate2 2s linear infinite;
        //     animation-delay: 1s;
        //   }

        //   @keyframes animate2 {
        //     0% {
        //       transform: translateY(-100%);
        //     }

        //     100% {
        //       transform: translateY(100%);
        //     }
        //   }
        // }

        // span {
        //   &:nth-child(3) {
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     width: 100%;
        //     height: 3px;
        //     background: linear-gradient(to left, #171618, #008cd3);
        //     animation: animate3 2s linear infinite;
        //   }

        //   @keyframes animate3 {
        //     0% {
        //       transform: translateX(100%);
        //     }

        //     100% {
        //       transform: translateX(-100%);
        //     }
        //   }
        // }

        // span {
        //   &:nth-child(4) {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     height: 100%;
        //     width: 3px;
        //     background: linear-gradient(to top, #171618, #008cd3);
        //     animation: animate4 2s linear infinite;
        //     animation-delay: 1s;
        //   }

        //   @keyframes animate4 {
        //     0% {
        //       transform: translateY(100%);
        //     }

        //     100% {
        //       transform: translateY(-100%);
        //     }
        //   }
        // }



    }

    .filter_mobile {
        display: none;

        :global(.filter_btn) {
            border: 1px solid #474747;
            background-color: #fafafa;
            margin-top: 6px;
            border-radius: 7px;
            padding: 6px 10px;
            line-height: 30px;
            color: #000;

            :global(svg) {
                transform: rotate(90deg);
                font-size: 26px;
            }
        }
    }
}

.box_flexArea {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
}

.checkBox_customize {
    padding-bottom: 6px;

    :global(.form-check-input) {
        height: 1.4rem;
        font-size: 19px;
    }

    :global(form) {
        gap: 40px;
        padding-left: 10px;
    }

    :global(.form-switch) {
        :global(.form-check-label) {
            // font-size: 20px;
            // width: 2.2rem;
        }

        :global(.form-check-label) {
            font-size: 14px;
            margin-top: 5px;
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 480px) {
    .box_flexArea {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 12px;
        flex-direction: column-reverse;
        width: 100%;

        :global(.forMobile) {
            justify-content: end;
        }
    }

    .checkBox_customize {
        width: 100%;
    }

    .select_dropdownMain {
        .post_attachBtn {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {

    .main_heading {

        h1 {
            font-size: 24px;
        }
    }

    // .create_register {
    //     padding: 90px 0px;
    //     padding-bottom: 20px;
    //     background-position: right;
    // }

    .lookingSection {

        .filterBox {
            display: block;

            .boxCard {
                margin-bottom: 10px;
            }

            .searchButton {

                :global(.btn) {
                    min-width: 100%;
                    margin-left: 0px;
                }
            }
        }
    }

    .select_dropdownMain {
        padding-top: 10px !important;

        .orderStyle {
            order: -1;
        }

        .post_attachBtn {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 18px;
            width: 100%;
            position: relative;


            :global(.btn) {
                min-width: 135px;
                line-height: 30px;
                margin-top: 6px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0%);
                margin-left: 0px;
            }

            .filter_mobile {
                display: block;
            }
        }
    }

    .rentList_Sides {
        padding-top: 25px;
    }

    .filterHide_onMobile {
        display: none;
    }

}

@media (max-width: 991px) {
    .filter_topSlider {

        .slideMain_box {
            flex-wrap: wrap-reverse;

            .left {
                width: 100%;

                :global(.owl-theme) {

                    :global(.owl-nav) {

                        :global(.owl-next) {
                            right: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
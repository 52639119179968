.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 190px 0px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.profileSection {
  padding-top: 30px;

  .profileBox {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 12px 11px;
    text-decoration: none;
    border-top: 2px solid #dad7d7;
    border-bottom: 2px solid #dad7d7;
    border-radius: 0px;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        border-radius: 9px;
        height: 60px;
        width: 60px;
      }

      .details {
        margin-left: 14px;

        h6 {
          font-size: 13px;
          margin-bottom: 4px;
        }

        h5 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0;
          color: #000;
        }
      }
    }

    .right {
      :global(.btn) {
        border-radius: 5px;
        font-size: 15px;
        background: #008cd3;
        padding: 10px 15px;
        border: 1px solid #008cd3;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

.categories_slider {
  .main_heading {
    text-align: center;

    h2 {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 13px;
    }

    p {
      margin-bottom: 9px;
    }
  }

  .left {
    margin: 0px 7px 10px;

    :global(.form-check) {
      :global(.form-check-input:checked[type="checkbox"]) {
        background-color: #ff0000;
        border: #ff0000;
        border-radius: 2px;
        background-image: none;
      }

      :global(.form-check-label) {
        font-weight: 600;
      }
    }
  }

  :global(.carousel) {
    :global(.control-arrow) {
      height: fit-content !important;
      top: 50% !important;
      transform: translate(0%, -50%);
      border-radius: 7px;
      background: #ebebeb !important;
      display: none;
    }

    :global(.control-dots) {
      margin: 2px 0;

      :global(.dot) {
        background-color: #c56363;
      }
    }

    :global(.slider-wrapper) {
      :global(.slide) {
        .sliderImage {
          border: 1px solid #dad7d7;
          border-radius: 8px;
          position: relative;
          padding: 28px;

          .soldProductLabel {
            position: absolute;
            top: 7px;
            left: 7px;
            z-index: 9;
            background: red;
            color: #fff;
            padding: 2px 12px;
            font-size: 12px;
            border-radius: 10px 10px 10px 10px;
          }

          :global(img) {
            height: 250px;
            object-fit: contain;
          }
        }
      }
    }

    :global(.thumbs-wrapper) {
      :global(.thumbs) {
        margin-top: 20px;
        text-align: center;
        padding-left: 0px;

        :global(.thumb) {
          border-radius: 3px;
          padding: 4px;
          border: 2px solid #9c8f8f;

          :global(img) {
            height: 72px;
            object-fit: contain;
          }

          &:global(.selected) {
            border: 2px solid #333;
          }

          &:hover {
            border: 2px solid #333;
          }
        }
      }
    }
  }

  .table_specifyBox {
    margin-top: 12px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .postBtn {
      margin-left: 8px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      :global(.btn) {
        border-radius: 5px;
        font-size: 15px;
        background: #008cd3;
        padding: 15px 35px;
        border: 1px solid #008cd3;
        color: #fff;
        font-weight: 500;
      }

      :global(.sellerBtn) {
        border: 1px solid #008cd3;
        background-color: #fff;
        color: #008cd3;
        transition: 0.3s;

        &:hover {
          background: #008cd3;
          border: 1px solid #008cd3;
          color: #fff;
        }
      }
    }
  }

  :global(.carousel-status) {
    display: none;
  }

  .sellerText_input {
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .sellerdetailstext {
    h4 {
      font-size: 16px;
      color: #000000;
      font-weight: bolder;

      span {
        padding: 0px 5px;

        font-size: 16px;
      }
    }

    p {
      b {
        margin-right: 5px;
      }
    }

    .typeText_box {
      textarea {
        height: 80px;
      }

      button {
        background: #008cd3;
        color: #fff;
        border-radius: 5px;
        border: navajowhite;
        width: 100px;
        height: 40px;
        margin-top: 15px;
      }
    }

  }
}

.reviewSection {
  padding-bottom: 80px;

  .productDescription {
    :global(.nav-tabs) {
      li {
        button {
          color: #000;

          font-weight: 400;
          font-size: 15px;
          border: none;

          &:hover {
            border-bottom: 2px solid #dd1111 !important;
            border: none;
            color: #dd1111;
          }
        }

        :global(.active) {
          border-bottom: 2px solid #dd1111 !important;
          border: none;
          color: #dd1111;
        }
      }
    }

    .content {
      p {
        font-size: 15px;
        margin-bottom: 20px;
        color: #5d6f7d;
        font-weight: 400;

        span {
          color: #000;
          font-weight: 600;
        }
      }
    }

    .comments_mainBox {
      padding: 15px;
      background-color: #fff;
      margin-top: 20px;

      .heading_top {
        font-size: 16px;
        font-weight: 600;
        background: #04091e;
        color: #fff;
        padding: 10px 25px;
        margin-bottom: 16px;
      }

      .commentsFlex {
        display: flex;
        gap: 12px;

        .left_Imgbox {
          img {
            width: 38px;
            height: 38px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .right_detailBox {
          .name_date {
            display: flex;
            align-items: baseline;
            gap: 8px;
            margin-bottom: 5px;

            h6 {
              font-size: 13px;
              font-weight: 600;
              margin: 0;
            }

            span {
              font-size: 12px;
              color: #777777;
            }
          }

          .content_text {
            font-size: 14px;
          }

          .likeDislike_reply {
            display: flex;

            .like_btn,
            .dislike_btn {
              margin-right: 10px;
              text-wrap: nowrap;

              button {
                border: none;
                background: transparent;

                :global(svg) {
                  font-size: 20px;
                }
              }

              small {
                font-size: 11px;
                color: #575757e3;
              }
            }

            .reply_btn,
            .report_btn {
              margin-right: 10px;

              :global(.btn) {
                background-color: transparent;
                color: #000;
                border: 1px solid #000;
                border-radius: 20px;
                font-size: 12px;
                padding: 7px 12px;
              }
            }
          }
        }
      }

      .messageForm_box {
        :global(.btn) {
          background-color: #008cd3;
          color: #fff;
          padding: 7px 20px;
        }
      }
    }
  }
}

.addMessage_send_box {
  display: flex;
  gap: 14px;
  margin-top: 10px;

  :global(.form-control) {
    box-shadow: none;
  }

  :global(.btn) {
    background-color: #008cd3;
    color: #fff;
    padding: 6px 20px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {}

@media (max-width: 767px) {

  .main_heading {
    h1 {
      font-size: 24px;
    }
  }

  .create_register {
    padding: 90px 0px;
    padding-bottom: 20px;
    background-position: right;
  }

  .pageSpace {
    padding: 40px 0px;
  }

  .categories_slider {
    :global(.carousel) {
      :global(.slider-wrapper) {
        :global(.slide) {
          .sliderImage {
            padding: 15px 15px 24px;

            :global(img) {
              height: 201px;
            }
          }
        }
      }
    }
  }

  .profileSection {
    .profileBox {

      padding: 12px 4px;

      // flex-direction: column;
      // align-items: flex-start;
      .left {
        .details {
          h6 {
            font-size: 11px;
          }

          h5 {
            font-size: 15px;
          }
        }
      }

      .right {
        :global(.btn) {
          font-size: 11px;
          padding: 5px 6px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
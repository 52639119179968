* {
    list-style-type: none;
    text-decoration: none !important;
}

.searchBarBox {
    position: absolute;
    left: 0;
    max-height: 250px;
    top: 100%;
    margin-top: 4px;
    overflow-y: auto;
    background: #fff;
    z-index: 99;
    border-radius: 10px;
    border: 1px solid gainsboro;
}

.titleBox {
    margin: 0;
    padding: 6px 6px;
    color: #fff;
    border-radius: 8px;
    background-color: #1c9cea;
}

.ListBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
    margin-bottom: 10px;
    box-shadow: 0 0 14px -12px #0014ff;
    padding: 6px;
    border-radius: 8px;
    text-transform: capitalize;
    justify-content: space-between;
}

.ListBox>a>p {
    margin: 0;
}

.ListBox>p {
    width: 100%;
    font-size: 12px;
}

.ListBox .childOne {
    width: 70%;
    color: #000;
}

.ListBox>a:nth-child(3n) {
    width: 100%;
}

.ListBox .childTwo {
    width: 30%;
}

.titleUp{
    width: 30%;
    text-transform: capitalize;
    text-align: right;
    color: #1c9cea;
}
.horizonalCard_sec {
    padding: 50px 0px;
}

.card_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;

    .heading {
        font-weight: 600;
        font-size: 27px;
        margin-bottom: 2px;
    }
}

.cardBox_main {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .leftMain {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    .leftContent {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .dateBox{
            font-size: 13px;

            svg{
                color: #008cd3;
                margin-right: 5px;
            }
        }
        h3 {
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 0px;
        }

        h4 {
            font-size: 15px;
            margin-bottom: 0;
            font-weight: 600;
            color: #484444;
        }
    }

    .imgBox {
        min-width: 160px;
        height: 80px;
        border-radius: 4px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }
    }

    .btnBox {
        display: flex;
        align-items: flex-end;
        gap: 7px;
        flex-direction: column;
        
        :global(.btn) {
            color: #fff;
            border: transparent;
            background: #008cd3;
            border-radius: 8px;
            padding: 7px 20px;
            display: inline-flex;
            align-items: center;
            gap: 6px;
            font-size: 14px;
            transition: .3s;

            &:hover {
                background: #0179b4;
            }

            svg {
                font-size: 16px;
                padding-top: 2px;
            }
        }
    }
}
.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 190px 0px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding: 70px 0px;
}

.productForm_section {
  .product_mainbox {
    background: #fff;
    -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 40px;

    .step_progress {
      margin-bottom: 28px;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;
        margin: 0 auto;
        padding-left: 5px;

        li {
          display: inline-block;

          .stepp {
            display: inline-block;
            vertical-align: middle;
            background-size: contain;
            height: 38px;
            width: 38px;

            &.one,
            &.two {
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 3px solid #403f3f;
              position: relative;
              background: #bababa;
              text-align: center;
            }

            .step_number {
              background: #bababa;
              display: inline-block;
              min-width: 100%;
              min-height: 100%;
              line-height: 32px;
              border-radius: 50%;
              color: #f2f2f2;
            }

            &.active {
              .step_number {
                background: initial !important;
              }

              .line {
                border-bottom: none;
              }
            }

            .line {
              content: "";
              position: absolute;
              left: 37px;
              right: 0;
              width: 86px;
              height: 3px;
              background: #bababa;
              border-bottom: 4px solid #ddd;
            }

          }


        }
      }
    }

    form {

      .form_heading {
        h3 {
          font-size: 24px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 22px;
          text-align: center;
          background: #2a3842;
          padding: 10px 0px;
        }
      }

      .nextBack_btn {
        display: flex;
        justify-content: space-between;

        :global(.btn) {
          background-color: #42b4e6;
          color: #fff;
          min-width: 140px;
          line-height: 32px;
          font-size: 17px;
          border: 2px solid #42b4e6;
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {}

@media (max-width:767px) {
  .create_register {
    padding: 90px 0px;
    padding-bottom: 20px;
    background-position: right;
  }

  .pageSpace {
    padding: 40px 0px;
  }

  .productForm_section {
    .product_mainbox {
      padding: 40px 18px;
    }
  }
}
.filter_topSlider {

    .slideMain_box {
        display: flex;
        justify-content: space-between;

        .left {
            width: 97%;
            .boxes {
                text-align: center;
                display: block;
                color: #716d6d;
                text-decoration: none;
                padding-bottom: 10px;
                transition: color 0.3s ease;
                position: relative;

                &:hover{

                    &::before{
                        background-color: #716d6d !important;
                    }
                }


                &::before {
                    content: "";
                    position: absolute;
                    width: 50%;
                    height: 3px;
                    background-color: #000;
                    bottom: 2px;
                    left: 25%;
                    border-radius: 4px;
                    transform: scaleX(0);
                    transform-origin: bottom center;
                    transition: transform 0.3s ease;
                }

                &:hover,
                &.active {
                    color: #000000;

                    &::before {
                        transform: scaleX(1);
                    }
                }
                
                svg {
                    margin-bottom: 10px;
                    font-size: 30px;
                }

                p {
                    margin-bottom: 0px;
                }
            }

            :global(.owl-theme) {
                position: relative;

                :global(.owl-nav) {

                    :global(.owl-prev),
                    :global(.owl-next) {
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -150%);
                        border: 1px solid #000;
                        border-radius: 50%;
                        display: flex;
                        padding: 3px !important;
                    }

                    :global(.owl-prev) {
                        left: 0;
                    }

                    :global(.owl-next) {
                        right: -30px;
                    }

                    :global(.disabled) {
                        display: none !important;
                    }
                }
            }
        }

        // .right {
        //     width: 9%;

        //     .filterBtn {

        //         text-align: end;

        //         :global(.btn) {
        //             border-radius: 20px;

        //             :global(svg) {
        //                 margin-right: 5px;
        //             }
        //         }
        //     }
        // }

    }

}

.rentList_Sides {
    .main_box {
        position: relative;

        .Topbox_Reatives {
            text-decoration: none;
            color: #000000;

            .imgBox {
                img {
                    height: 271px;
                    object-fit: cover;
                }
            }

            .contentArea {
                padding: 12px;
                padding-bottom: 14px;
                box-shadow: 0px 0px 5px 0px #0000001A;

                .headLine {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 8px;

                    h4 {
                        font-size: 22px;
                    }

                    .rating {
                        display: flex;
                        font-weight: 700;
                        align-items: baseline;

                        :global(svg) {
                            color: #FFEA00;
                            margin-right: 4px;
                        }
                    }
                }

                .timing_prices {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .time {
                        display: flex;
                        align-items: center;

                        :global(svg) {
                            color: #008cd3;
                            margin-right: 8px;
                        }
                    }

                    .price {
                        color: #FF0000;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }
        }

        .bottomBox_absolute {
            position: absolute;
            top: 11px;
            right: 20px;
            color: #fff;
            cursor: pointer;
            transition: .3s;

            &:hover{
                color: #FF0000;
            }
        }
    }
}

.paginationBox{
    :global(.pagination){
        justify-content: center;
    }
}

.suggestAddsSec_slider{
    h2{
        font-size: 35px;
        font-weight: 600;
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {

    .lookingSection {

        .filterBox {
            display: block;

            .boxCard {
                margin-bottom: 10px;
            }

            .searchButton {

                :global(.btn) {
                    min-width: 100%;
                    margin-left: 0px;
                }
            }
        }
    }

}
@media (max-width: 991px) {
    .filter_topSlider {

        .slideMain_box {
            flex-wrap: wrap-reverse;

            .left{
                width: 100%;

                :global(.owl-theme) {

                    :global(.owl-nav) {

                        :global(.owl-next) {
                            right: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
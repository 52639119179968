/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.priceSignBox {
  position: absolute;
  top: 0;
  right: 0;
  background: #42b4e6;
  line-height: 37px;
  padding: 0px 10px;
  border-radius: 0px 4px 4px 0px;
  font-weight: 600;
}

.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 190px 0px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding: 70px 0px;
}

.productForm_section {
  .product_mainbox {
    background: #fff;
    -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 40px;

    form {
      .form_heading {
        h3 {
          font-size: 24px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 22px;
          text-align: center;
          background: #2a3842;
          padding: 10px 0px;
        }
      }

      .nextBack_btn {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        :global(.btn) {
          background-color: #42b4e6;
          color: #fff;
          min-width: 100%;
          line-height: 32px;
          font-size: 17px;
          border: 2px solid #42b4e6;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {}

@media (max-width:767px) {
  .create_register {
    padding: 140px 0px;
    padding-bottom: 68px;
  }

  .pageSpace {
    padding: 40px 0px;
  }
}
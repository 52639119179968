

.main_header {
	position: fixed;
	width: 100%;
	z-index: 999;
	transition: 0.2s;
	border-bottom: 3px solid #c2bdbd;
	border-image: linear-gradient(45deg, #1599d8, #c4323e, #feee54) 1;

	

	:global(.navbar){
		padding: 18px 0px;

		:global(.navbar-collapse) {
			:global(.navbar-nav){
				:global(.nav-link){
					color: #000;

					&:global(.contact_Btn){
						background-color: #008cd3;
						border-radius: 5px;
						color: #fff;
						padding: 12px 42px;
					}
				}
				
			}

		}
	}
	&.affix{
		background-color: #f6f6f6;
		box-shadow: 0px -1px 9px 0px #847f7f;
	}

}





/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {

}



@media only screen and (min-width:992px) and (max-width:1199px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}


.chat {
    background: #f7f7f7;

    .chat_box{
        :global(.text-muted){
            color: #8492a6 !important;
        }
        :global(.hover-Overlay){
            &:hover{
                background-color: #c2dbffb9;
            }
        }
    }

}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
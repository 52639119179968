.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #ecf0f1;
}

.title_TopHeading {
  h4 {
    font-size: 14px;
    font-weight: 600;
    background: #ffffff;
    color: #000000;
    padding: 10px 15px;

    span {
      color: #008cd3;
    }
  }
}

.post_list_left {
  padding: 15px;
  background-color: #fff;

  .post_wrapper {
    .Top_img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .content_details {
      padding: 22px 23px;

      .tag_line {
        display: inline-block;
        background: #008cd3;
        color: #fff;
        padding: 4px 30px;
        font-weight: 300;
        font-size: 15px;
      }

      h3 {
        color: #060606;
        margin: 17px 0px;
        font-size: 22px;
        font-weight: 600;
      }

      .date_comments {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 11px;

        li {
          .flex_box {
            color: #777777;
            display: flex;
            align-items: center;
            font-size: 13px;
            gap: 4px;
          }
        }
      }

      .share_icons {
        margin-top: 17px;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        display: flex;
        align-items: center;

        li {
          margin-right: 6px;

          :global(a) {
            display: inline-flex;
            padding: 9px 11px;
            border-radius: 4px;
            font-size: 13px;
            position: relative;
            top: 0px;
            transition: opacity 0.2s ease-in, top 0.2s ease-in;

            &:hover {
              top: -3px;
            }

            :global(svg) {
              color: #fff;
            }
          }
        }
        li {
          &:nth-child(1) {
            :global(a) {
              background-color: #4267b2;
            }
          }
          &:nth-child(2) {
            :global(a) {
              background-color: #0077b5;
            }
          }
          &:nth-child(3) {
            :global(a) {
              background-color: #448aff;
            }
          }
          &:nth-child(4) {
            :global(a) {
              background-color: #1c9cea;
            }
          }
          &:nth-child(5) {
            :global(a) {
              background-color: #7d7d7d;
            }
          }
        }
      }

      .para_box {
        h6 {
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          color: #2b2a2a;
          font-size: 15px;
        }
      }

      .navigation_wrapBtn {
        display: flex;
        justify-content: space-between;

        .prev_next {
          color: #fff;
          text-decoration: none;
          padding: 11px 14px;
          background: #008cd3;
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          min-width: 97px;
        }
      }

      .addFavorite {
        margin-top: 9px;

        button {
          display: inline-flex;
          width: max-content;
          align-items: center;
          gap: 7px;
          border: 1px solid #000000;
          background: none;
          color: #000000;
          border-radius: 26px;
          padding: 8px 11px;
          font-size: 14px;

          &:hover {
            :global(svg) {
              stroke: #008cd3 !important;
            }
          }

          :global(svg) {
            stroke: #000000 !important;
            strokewidth: 40px;
            display: block;
            height: 18px;
            overflow: visible;
            width: 18px;
            fill: #446db021;
          }

          &.active {
            :global(svg) {
              stroke: #fff !important;
              fill: #008cd3;
            }
          }
        }
      }
    }
  }
}

.comments_mainBox {
  padding: 15px;
  background-color: #fff;
  margin-top: 20px;

  .heading_top {
    font-size: 16px;
    font-weight: 600;
    background: #04091e;
    color: #fff;
    padding: 10px 25px;
    margin-bottom: 16px;
  }

  .commentsFlex {
    display: flex;
    gap: 12px;

    .left_Imgbox {
      img {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .right_detailBox {
      .name_date {
        display: flex;
        align-items: baseline;
        gap: 8px;
        margin-bottom: 5px;

        h6 {
          font-size: 13px;
          font-weight: 600;
          margin: 0;
        }

        span {
          font-size: 12px;
          color: #777777;
        }
      }

      .content_text {
        font-size: 14px;
      }

      .likeDislike_reply {
        display: flex;

        .like_btn,
        .dislike_btn {
          margin-right: 10px;
          text-wrap: nowrap;

          button {
            border: none;
            background: transparent;

            :global(svg) {
              font-size: 20px;
            }
          }

          small {
            font-size: 11px;
            color: #575757e3;
          }
        }

        .reply_btn,
        .report_btn {
          margin-right: 10px;

          :global(.btn) {
            background-color: transparent;
            color: #000;
            border: 1px solid #000;
            border-radius: 20px;
            font-size: 12px;
            padding: 7px 12px;
          }
        }
      }
    }
  }

  .messageForm_box {
    :global(.btn) {
      background-color: #008cd3;
      color: #fff;
      padding: 7px 20px;
    }
  }
}

.post_list_right {
  padding: 15px;
  background-color: #fff;

  .heading_top {
    font-size: 15px;
    font-weight: 600;
    background: #04091e;
    color: #fff;
    padding: 10px 25px;
    margin-bottom: 16px;
  }

  .attach_box {
    display: block;
    text-decoration: none;
    color: #000;

    &:hover {
      .relative_box {
        .rightBox_img {
          transform: scale(1.1);
        }

        &::after {
          opacity: 0.1;
        }
      }
    }

    .relative_box {
      position: relative;
      overflow: hidden;

      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background: #000;
      }

      .rightBox_img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.3s ease 0s;
      }

      .tag_Box {
        position: absolute;
        bottom: 10px;
        left: 12px;
        z-index: 1;

        .tag_line {
          display: inline-block;
          background: #008cd3;
          color: #fff;
          padding: 4px 30px;
          font-weight: 300;
          font-size: 15px;
        }
      }
    }

    h3 {
      font-size: 18px;
      margin-top: 14px;
      font-weight: 600;
    }

    .date_comments {
      padding-left: 0;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 8px;

      li {
        .flex_box {
          color: #777777;
          display: flex;
          align-items: center;
          font-size: 13px;
          gap: 4px;

          :global(svg) {
            position: relative;
            top: -1px;
          }
        }
      }
    }

    p {
      color: #2b2a2a;
      font-size: 15px;
    }
  }

  .recommend_Smallbox {
    display: block;
    text-decoration: none;
    color: #000;

    &:hover {
      .flex_box {
        .right_sideDetail {
          h4 {
            color: #008cd3;
          }
        }
      }
    }

    .flex_box {
      display: flex;
      align-items: center;

      .left_sideImg {
        width: 30%;

        .ImgBox {
          width: 100%;
          height: 81px;
          object-fit: cover;
        }
      }

      .right_sideDetail {
        width: 70%;
        margin-left: 16px;

        h4 {
          font-size: 14px;
          font-weight: 600;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          transition: 0.3s;
        }

        .date_comments {
          padding-left: 0;
          list-style: none;
          display: flex;
          align-items: center;
          gap: 13px;
          margin-bottom: 4px;

          li {
            .flex_box {
              color: #777777;
              display: flex;
              align-items: center;
              font-size: 12px;
              gap: 4px;

              :global(svg) {
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .create_register {
    padding: 140px 0px;
    padding-bottom: 68px;
  }
  .pageSpace {
    padding: 40px 0px;
  }
}

@media (max-width: 480px) {
  .post_list_left {
    .post_wrapper {
      .content_details {
        .addFavorite {
          width: 100%;
          margin-top: 14px;
        }
      }
    }
  }
}

.main_header {
  position: fixed;
  width: 100%;
  z-index: 999;
  transition: 0.2s;
  // border-bottom: 4px solid #c2bdbd;
  // // border-image: linear-gradient(90deg, #009ad7, #c93837, #ffe854) 1;
  // border-image: linear-gradient(110deg, rgba(43, 116, 177, 1) 49.2%, rgba(198, 29, 40, 1) 49%,
  // 		rgba(198, 29, 40, 1) 80.7%, rgba(249, 221, 24, 1) 77%, rgba(249, 221, 24, 1) 85%) 1;

  :global(.navbar) {
    padding: 5px 0px;
    // padding: 10px 0px;
    background-image: url(../../../assets/images/header-new_img.png);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 72px;

    :global(.navbar-toggler) {
      background-color: aliceblue;

      &:focus {
        outline: transparent;
      }
    }

    :global(.navbar-brand) {

      // h3{
      // 	color: #fff;
      // }
      img {
        // width: 100%;
        width: 230px;
        height: 35px;
        object-fit: contain;
        filter: drop-shadow(2px 4px 6px black);
      }
    }

    :global(.navbar-collapse) {
      :global(.nav-link) {
        color: #fff;

        &:global(.contact_Btn) {
          background-color: #008cd3;
          border-radius: 5px;
          color: #fff;
          padding: 12px 28px;
        }
      }

      :global(.chat-notification) {
        position: relative;
        background-color: transparent;
        color: #000;
        border-radius: 25px;
        margin-right: 11px;

        :global(svg) {
          font-size: 15px;
          position: relative;
          top: -1.3px;
        }

        :global(.notify) {
          position: absolute;
          font-size: 8px;
          background: red;
          color: #fff;
          padding: 1px 3px;
          top: 6px;
          border-radius: 3px;
          min-width: 14px;
          min-height: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 2px;
        }
      }

      :global(.navbar-nav) {
        .globeMain {
          display: flex;
          align-items: center;

          .globe_imgBox {
            display: flex;

            .imgs {
              width: 15px;
            }
          }

          :global(.form-select) {
            border: none;
            box-shadow: none;
            background-size: 16px !important;
            padding-left: 2px;
            padding-right: 19px;
            background: transparent;
            background-image: url(../../../assets/images/down-arrow.svg);
            background-repeat: no-repeat;
            background-position: 90% 50%;
            max-width: 6px;
            cursor: pointer;
          }
        }

        :global(.dropdown) {
          :global(.dropdown-menu) {
            left: inherit;
            right: 0;

            ul {
              padding-left: 0;
              list-style: none;
              margin-bottom: 0px;

              li {
                a {
                  display: block;
                  width: 100%;
                  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
                  clear: both;
                  font-weight: 400;
                  color: var(--bs-dropdown-link-color);
                  text-align: inherit;
                  text-decoration: none;
                  white-space: nowrap;
                  background-color: transparent;
                  border: 0;
                  border-radius: var(--bs-dropdown-item-border-radius, 0);

                  &:hover {
                    color: var(--bs-dropdown-link-hover-color);
                    background-color: var(--bs-dropdown-link-hover-bg);
                  }

                  &:focus {
                    color: var(--bs-dropdown-link-hover-color);
                    background-color: var(--bs-dropdown-link-hover-bg);
                  }
                }
              }
            }
          }

          :global(.btn) {
            background-color: transparent;
            color: #fff;
            border-radius: 25px;
            border: 1px solid rgb(255, 255, 255);
            padding: 5px 13px;

            .hamburger {
              margin-right: 13px;
              width: 17px;
              filter: brightness(0) invert(1);
            }

            .profile_person {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
          }

          :global(.dropdown-toggle) {
            &::after {
              display: none;
            }
          }
        }

        :global(.post-dropdown) {
          :global(.btn) {
            border: none;
            padding: 0 6px;

            .arrow_down {
              width: 16px;
              margin-left: 5px;
              filter: brightness(0) invert(1);
            }
          }
        }

        .service_dropdown {
          :global(.btn) {
            border: none;
            padding: 8px;
          }

          :global(.dropdown-toggle) {
            &::after {
              display: inline-block;
            }
          }
        }
      }
    }

    :global(.seach_relatedBtns) {
      position: relative;

      // new dropdown
      :global(.css-b62m3t-container) {
        position: absolute;
        right: 55px;
        top: 3px;
        width: 162px;

        :global(.css-t3ipsp-control) {
          box-shadow: none !important;
          border-top: none;
          border-bottom: none;
          border-radius: 0px;
          box-shadow: none !important;

          // &:hover {

          // }
        }

        :global(.css-13cymwt-control) {
          border-top: none;
          border-bottom: none;
          border-radius: 0;
          font-size: 15px;

          :global(.css-1u9des2-indicatorSeparator) {
            display: none;
          }
        }

        :global(.css-1xc3v61-indicatorContainer) {
          color: #000;
        }
      }

      // end

      :global(.form-control) {
        padding: 9px 10px;
        padding-right: 40px;
        border-radius: 25px;
        border: 1px solid #000;
      }

      :global(.btn) {
        padding: 3px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: #ff0000;
        color: #fff;
        border: 1px solid transparent;

        :global(svg) {
          width: 22px;
          height: 17px;
          position: relative;
          top: -3px;
        }
      }

      :global(.form-select) {
        position: absolute;
        top: 11px;
        width: 170px;
        right: 56px;
        box-shadow: none;
        border: none;
        border-radius: 0;
        border-left: 1px solid #c8bfbf;
        border-right: 1px solid #c8bfbf;
        line-height: 18px;
        background-color: #ffffff;
        color: #000;
        padding: 2px 12px;
        padding-right: 31px;
      }
    }
  }

  :global(.inner-page-category) {
    :global(.form-control) {
      padding-right: 35px !important;
      width: 100%;
    }
  }

  :global(.seach_bar_mobile_show) {
    display: none;
  }

  :global(.mobile_show_seachBtn) {
    display: none;
  }

  &.affix {
    background-color: #f6f6f6;
    box-shadow: 0px -1px 9px 0px #847f7f;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  :global(.navbar-brand) {

    // h3{
    // 	color: #fff;
    // }
    img {
      width: 100% !important;
      height: 28px !important;
      filter: drop-shadow(2px 4px 6px black);
    }
  }
}

@media (max-width: 521px) {
  .main_header {
    :global(.navbar) {
      flex-wrap: wrap;

      .mobile_containerBox {
        flex-wrap: nowrap !important;
      }

      :global(.seach_bar_mobile_hide) {
        display: none !important;
      }

      :global(.seach_bar_mobile_show) {
        display: block;
        position: relative;

        :global(.form-control) {
          margin: 9px 0px;
          padding: 9px;
        }

        :global(.btn) {
          position: absolute;
          padding: 3px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          position: absolute;
          right: 19px;
          top: 16px;
          background-color: #ff0000;
          color: #fff;
          border: 1px solid transparent;

          svg {
            width: 22px;
            height: 17px;
            position: relative;
            top: -3px;
          }
        }
      }

      :global(.mobile_show_seachBtn) {
        display: block;

        :global(.btn) {
          padding: 3px;
          width: 30px;
          height: 30px;
          background-color: #ff0000;
          color: #fff;
          border-radius: 50%;
          border: 1px solid transparent;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          :global(svg) {
            width: 22px;
            height: 17px;
          }
        }
      }
    }
  }

  .showHeight {
    height: 42px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}
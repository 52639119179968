
.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;
}

.favouriteSection{

  .title{
    margin-bottom: 30px;

    h1{
      text-align: center;
      font-size: 32px;
      color: #000;
      margin-bottom: 0px;
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #ecf0f1;
}

.title_TopHeading {
  h4 {
    font-size: 14px;
    font-weight: 600;
    background: #ffffff;
    color: #000000;
    padding: 10px 15px;

    span {
      color: #008cd3;
    }
  }
}

.heart {
  button {
    border: none;
    background: none;
    padding: 0;

    display: inline-flex;
    width: max-content;
    align-items: center;
    gap: 7px;
    border: 1px solid #000000;
    background: none;
    color: #000000;
    border-radius: 26px;
    padding: 8px 11px;
    font-size: 14px;

    :global(svg) {
      display: block;
      fill: rgba(189, 186, 186, 0.52);
      height: 20px;
      width: 20px;
      stroke: #000000 !important;
      strokewidth: 40px;
      overflow: visible;
    }

    &:hover {
      :global(svg) {
        fill: rgb(255 0 0 / 65%);
      }
    }

    &.active {
      :global(svg) {
        fill: rgb(255 0 0 / 100%);
      }
    }
  }
}

.post_list_left {
  padding: 15px;
  background-color: #fff;

  .post_wrapper {
    .Top_img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .content_details {
      padding: 22px 23px;

      .tag_line {
        display: inline-block;
        background: #008cd3;
        color: #fff;
        padding: 4px 30px;
        font-weight: 300;
        font-size: 15px;
      }

      h3 {
        color: #060606;
        margin: 17px 0px;
        font-size: 22px;
        font-weight: 600;
      }

      .date_comments {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 11px;

        li {
          .flex_box {
            color: #777777;
            display: flex;
            align-items: center;
            font-size: 13px;
            gap: 4px;
          }
        }
      }

      .share_icons {
        margin-top: 17px;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        display: flex;
        align-items: center;

        li {
          margin-right: 6px;

          :global(a) {
            display: inline-flex;
            padding: 9px 11px;
            border-radius: 4px;
            font-size: 13px;
            position: relative;
            top: 0px;
            transition: opacity 0.2s ease-in, top 0.2s ease-in;

            &:hover {
              top: -3px;
            }

            :global(svg) {
              color: #fff;
            }
          }
        }

        li {
          &:nth-child(1) {
            :global(a) {
              background-color: #4267b2;
            }
          }

          &:nth-child(2) {
            :global(a) {
              background-color: #0077b5;
            }
          }

          &:nth-child(3) {
            :global(a) {
              background-color: #448aff;
            }
          }

          &:nth-child(4) {
            :global(a) {
              background-color: #1c9cea;
            }
          }

          &:nth-child(5) {
            :global(a) {
              background-color: #7d7d7d;
            }
          }
        }
      }

      .para_box {
        .tender_detailImg {
          width: 100%;
          height: auto;
        }

        h6 {
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          color: #2b2a2a;
          font-size: 15px;
        }
      }

      .navigation_wrapBtn {
        display: flex;
        justify-content: space-between;

        .prev_next {
          color: #fff;
          text-decoration: none;
          padding: 11px 14px;
          background: #008cd3;
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          min-width: 97px;
        }
      }

      .addFavorite {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .chatBtn {
          display: inline-flex;
          text-decoration: none;
          margin-right: 10px;
          width: max-content;
          align-items: center;
          gap: 7px;
          border: 1px solid #008cd3;
          background: none;
          font-weight: 600;
          color: #008cd3;
          border-radius: 26px;
          padding: 8px 26px;
          font-size: 14px;
          transition: 0.3s;

          &:hover {
            background-color: #008cd3;
            color: #fff;
          }
        }

        // button {
        //   display: inline-flex;
        //   width: max-content;
        //   align-items: center;
        //   gap: 7px;
        //   border: 1px solid #000000;
        //   background: none;
        //   color: #000000;
        //   border-radius: 26px;
        //   padding: 8px 11px;
        //   font-size: 14px;

        //   &:hover {
        //     :global(svg) {
        //       stroke: #008cd3 !important;
        //     }
        //   }

        //   :global(svg) {
        //     stroke: #000000 !important;
        //     strokeWidth: 40px;
        //     display: block;
        //     height: 18px;
        //     overflow: visible;
        //     width: 18px;
        //     fill: #446db021;
        //   }

        //   &.active {
        //     :global(svg) {
        //       stroke: #fff !important;
        //       fill: #008cd3;
        //     }
        //   }
        // }
      }
    }
  }
}

.post_list_right {
  padding: 15px;
  background-color: #fff;

  .heading_top {
    font-size: 15px;
    font-weight: 600;
    background: #04091e;
    color: #fff;
    padding: 10px 25px;
    margin-bottom: 16px;
  }

  .attach_box {
    display: block;
    text-decoration: none;
    color: #000;

    &:hover {
      .relative_box {
        .rightBox_img {
          transform: scale(1.1);
        }

        &::after {
          opacity: 0.1;
        }
      }
    }

    .relative_box {
      position: relative;
      overflow: hidden;

      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background: #000;
      }

      .rightBox_img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.3s ease 0s;
      }

      .tag_Box {
        position: absolute;
        bottom: 10px;
        left: 12px;
        z-index: 1;

        .tag_line {
          display: inline-block;
          background: #008cd3;
          color: #fff;
          padding: 4px 30px;
          font-weight: 300;
          font-size: 15px;
        }
      }
    }

    h3 {
      font-size: 18px;
      margin-top: 14px;
    }

    .date_comments {
      padding-left: 0;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 8px;

      li {
        .flex_box {
          color: #777777;
          display: flex;
          align-items: center;
          font-size: 13px;
          gap: 4px;
        }
      }
    }

    p {
      color: #2b2a2a;
      font-size: 15px;
    }
  }
}

:global(.tender_tag) {
  transform: rotate(180deg);
  font-size: 20px;

  &:global(.progressClr) {
    color: #60b900;
  }
}

.place_bodyTender {
  h6 {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #ae7c3a;
    margin-bottom: 10px;

    span {
      margin-right: 4px;
      font-weight: 600;
      font-size: 17px;
      display: inline-flex;
      align-items: center;
      gap: 2px;
      color: #000;

      :global(svg) {
        color: #232323;
      }
    }
  }
}

.pageSpace{
  padding-top: 70px;
  padding-bottom: 70px;

  .onboardingBox{
    overflow: hidden;
    background: #ffffff;
    padding: 24px;
    border-radius: 30px;
    border: 0px;
    box-shadow: 0px 0px 10px -2px rgb(224 224 224 / 81%);

    .onboardingHeading{
      text-align: center;

      h1{
        font-size: 32px;
        color: var(--Color);
        margin-bottom: 12px;
        font-weight: 700;
      }

      p{
        font-size: 16px;
        color: #5d6f7d;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }

    .formStart{

      :global(.form-control){
        height: 58px;
        font-size: 15px;
        box-shadow: none;
        border: 1px solid #e7edf1;
        border-radius: 8px;
        padding: 10px 10px 10px 50px;

        &:focus{
          outline: none;
          box-shadow: none;
        }
      }

      svg{
        font-size: 22px;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0%, -50%);
        color: #008cd3;
      }

      .onboardingButton{
        margin-bottom: 20px;

        :global(.btn){
          padding: 1em 1.5em;
          height: 62px;
          background-color: #008cd3;
          border: 1px solid #008cd3;
          color: #fff;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
      }

      .checkBox{

        label{

          a{
            text-decoration: none;
            color: #008cd3;
          }
        }
      }

      p{
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #5d6f7d;

        a{
          color: #008cd3;
          font-size: 15px;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
  .pageSpace{
  
    .onboardingBox{
  
      .onboardingHeading{
  
        h1{
          font-size: 26px;
        }
      }
    }
  }
}
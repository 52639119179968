.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 190px 0px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding-top: 35px;
  padding-bottom: 35px;

  .onboardingBox {
    overflow: hidden;
    background: #ffffff;
    padding: 24px;
    border-radius: 30px;
    border: 0px;
    box-shadow: 0px 0px 10px -2px rgba(184, 183, 183, 0.81);

    .onboardingHeading {
      text-align: center;

      h2 {
        font-size: 32px !important;
        color: var(--Color);
        margin-bottom: 7px;
        font-weight: 700;
      }

      p {
        font-size: 17px;
        color: #6a7075;
        font-weight: 400;
        margin-bottom: 13px;
      }
    }

    .formStart {
      .otpField {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 22px;
        justify-content: center;

        input {
          height: 60px !important;
          width: 60px !important;
          font-size: 16px;
          box-shadow: none;
          border: 1px solid #c3c4c6;
          border-radius: 4px;
          padding: 4px;
          text-align: center;

          &:focus {
            outline: none;
            box-shadow: none;
            border-color: var(--WebsiteMain);
          }
        }

        div {
          gap: 40px;
        }
      }

      .inputIcon {
        svg {
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translate(0%, -50%);
          color: var(--WebsiteMain);
        }
      }

      .onboardingButton {
        margin-bottom: 20px;

        :global(.btn) {
          padding: 1em 1.5em;
          height: 62px;
          background-color: #008cd3;
          border: 1px solid #008cd3;
          color: #fff;
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      p {
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #5d6f7d;

        a {
          color: var(--WebsiteMain);
          font-size: 15px;
          font-weight: 500;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;

          svg {
            font-size: 30px;
          }
        }
      }
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {}

@media (max-width:767px) {
  .create_register {
    padding: 140px 0px;
    padding-bottom: 68px;
  }
  .pageSpace {
    padding: 40px 0px;
  }
}

@media (max-width: 480px) {
  .formStart{
    .otpField{
     gap: 0px !important; 

     div{
      gap: 14px !important;
     }
    }
  }
}
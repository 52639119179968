.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 180px 0px;
  padding-bottom: 115px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ecf0f1;
}

.articles_main {
  position: relative;

  .article_attachBox {
    text-decoration: none;
    color: #020202;
    overflow: hidden;
    position: relative;
    display: block;
    border-radius: 4px;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #000;
      border-radius: 5px;
    }

    &:hover {
      .apply_img {
        transform: scale(1.1);
      }

      &::after {
        opacity: 0.1;
      }
    }

    .apply_img {
      width: 100%;
      height: 355px;
      object-fit: cover;
      transform: scale(1);
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }

    .content_details {
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 0;
      padding: 22px 23px;

      .tag_line {
        display: inline-block;
        background: #008cd3;
        color: #fff;
        padding: 4px 30px;
        font-weight: 300;
        font-size: 15px;
      }

      h3 {
        color: #f6f6f6;
        margin: 20px 0px;
        font-size: 20px;
      }

      .date_comments {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 20px;

        li {
          .flex_box {
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 13px;
            gap: 4px;
          }
        }
      }
    }
  }

  .addFavorite {
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 2;

    button {
      display: inline-flex;
      width: max-content;
      align-items: center;
      gap: 7px;
      border: 1px solid #fff;
      background: none;
      color: #fff;
      border-radius: 26px;
      padding: 8px 11px;
      font-size: 14px;

      &:hover {
        :global(svg) {
          stroke: #ffffff !important;
          fill: rgba(255, 0, 0, 0.7);
        }
      }

      :global(svg) {
        stroke: #fff !important;
        strokewidth: 40px;
        display: block;
        height: 18px;
        overflow: visible;
        width: 18px;
        fill: rgba(255, 0, 0, 0.28);
      }

      &.active {
        :global(svg) {
          stroke: #fff !important;
          fill: #ff0000;
        }
      }
    }
  }
}

.otherTitles_sec {
  .otherTitles_main {
    background-color: #fff;
    padding: 20px;

    .main_heading {
      h4 {
        font-size: 15px;
        font-weight: 600;
        background: #04091e;
        color: #fff;
        padding: 10px 25px;
      }
    }

    .latest_post {
      .postAttachment {
        text-decoration: none;
        color: #000;
        margin-top: 16px;
        display: block;

        &:hover {
          .post_left {
            .apply_img {
              transform: scale(1.1);
            }

            &::after {
              opacity: 0.1;
            }
          }
        }

        .post_left {
          position: relative;
          overflow: hidden;

          &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background: #000;
          }

          .apply_img {
            width: 100%;
            height: 185px;
            object-fit: cover;
            transform: scale(1);
            transition: all 0.3s ease 0s;
          }

          .tag_Box {
            position: absolute;
            bottom: 10px;
            left: 16px;
            z-index: 1;

            .tag_line {
              display: inline-block;
              background: #008cd3;
              color: #fff;
              padding: 4px 30px;
              font-weight: 300;
              font-size: 15px;
            }
          }
        }

        .details_right {
          h3 {
            font-size: 18px;
            font-weight: 600;
            display: block;
            padding-right: 29px;
          }

          .date_comments {
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 10px 0;

            li {
              .flex_box {
                color: #777777;
                display: flex;
                align-items: center;
                font-size: 13px;
                gap: 4px;
              }
            }
          }

          p {
            margin-bottom: 0px;
            color: #777777;
            font-size: 14px;
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .create_register {
    padding: 90px 0px;
    padding-bottom: 20px;
    background-position: right;

    .main_heading {
      h1 {
        font-size: 24px;
      }
    }
  }
  .pageSpace {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 16px;
  }
}

.create_register {
  background-image: url(../../../assets/images/banner-bg-main-new.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 190px 0px;
  position: relative;
  background-position: center;

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: #fff;
  }

  .main_heading {
    position: relative;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }
}

.pageSpace {
  padding-top: 70px;
  padding-bottom: 70px;

  .formBox {
    h2 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 30px;
      font-weight: 600;
      margin-top: 5%;
    }

    p {
      color: #000;
    }

    :global(.btn) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 600;
      padding: 10px 2px;
      border-radius: 0px;

      img {
        height: 16px;
      }
    }

    :global(form) {
      :global(label) {
        font-weight: 600;
      }
      .uploadIcon {
        padding-top: 5px;
        padding-left: 10px;
      }
      :global(.upload_img) {
        display: flex;
        align-items: center;
        gap: 12px;

        :global(label) {
          display: flex;
          align-items: center;
          gap: 6px;
        }
        
        :global(input) {
          display: none;
        }
      }

      :global(.form-control) {
        padding: 12px 13px;
        border-radius: 0px;
        border: 1px solid #000;
      }

      :global(.save) {
        background-color: #008cd3;
        color: #fff;
        border: 1px solid #008cd3;
      }
      :global(.cancel) {
        border: 1px solid #000;
      }
    }
  }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width:991px) {}

@media (max-width:767px) {
  .create_register {
    padding: 90px 0px;
    padding-bottom: 20px;
    background-position: right;
  }
  .pageSpace {
    padding: 40px 0px;
  }
}
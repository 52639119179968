.banner_main {
  // background-image: url(../../../assets/images/main-banner-new.png);
  width: 100%;
  // height: 20.5625vw;
  // height: 101px;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 30px 0px;
  background-position: 50% 3%;
  position: relative;

  .banner_boxes {


    .banner_attach {
      background: #fff;
      display: block;
      color: #000;
      text-decoration: none;
      padding: 8px 8px;
      border-radius: 11px;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0px;
      }

      &:nth-child(1) {
        .banner_FlexArea {
          .left {
            .img_box {
              background: #f5f5ba;
            }
          }
        }
      }

      &:nth-child(3) {
        .banner_FlexArea {
          .left {
            .img_box {
              background: #b3e4fa;
            }
          }
        }
      }

      .banner_FlexArea {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          .img_box {
            max-width: 44px;
            min-width: 44px;
            height: 44px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #daeefd;
            border-radius: 50%;

            img {
              width: 23px;
            }
          }

          .box_content {
            margin-left: 9px;

            h4 {
              margin-bottom: 5px;
              font-size: 13px;
              font-weight: 600;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            p {
              margin-bottom: 0px;
              font-weight: 600;
              font-size: 11px;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

              span {
                background-color: rgb(10 10 201);
                color: #fff;
                padding: 0px 8px;
                border-radius: 13px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                position: relative;
                top: 1px;
              }
            }
          }
        }

        .right {
          padding-right: 0px;

          :global(svg) {
            font-size: 20px;
          }
        }
      }
    }
  }

  .another_banner_boxes {
    .banner_attach {
      padding: 8px 8px;

      .banner_FlexArea {
        .left {
          .img_box {
            max-width: 44px;
            min-width: 44px;
            height: 44px;

            img {
              width: 23px;
            }
          }

          .box_content {
            h4 {
              font-size: 13px;
            }

            p {
              font-size: 11px;

              span {
                font-size: 10px;
                top: 0px;
              }
            }
          }
        }
      }
    }

    .back_btn {
      :global(.btn) {
        width: 27px;
        height: 27px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #f3f5f8;
        padding: 4px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 9px;

        &:active {
          border-color: transparent;
        }

        &:hover {
          :global(svg) {
            color: #0585c5;
          }
        }

        :global(svg) {
          font-size: 18px;
          color: #000;
          transition: 0.3s;
        }
      }
    }

    .back_btn2 {
      :global(.btn) {
        width: 27px;
        height: 27px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #f3f5f8;
        padding: 4px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 9px;
        opacity: 0;

        &:active {
          border-color: transparent;
        }

        &:hover {
          :global(svg) {
            color: #0585c5;
          }
        }

        :global(svg) {
          font-size: 18px;
          color: #000;
          transition: 0.3s;
        }
      }
    }
  }

  :global(.owl-carousel) {
    :global(.owl-item) {
      :global(.item) {
        :global(img) {
          height: 20.5625vw;
          object-fit: cover;
        }
      }
    }

    :global(.owl-dots) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      bottom: 0;

      :global(.owl-dot) {
        :global(span) {
          background: #4e4e4e;
          width: 8px;
          height: 8px;
          margin: 5px 3px;
          opacity: 0.8;
        }

        &:global(.active) {
          :global(span) {
            background: #007aff;
            opacity: 1;
          }
        }
      }
    }
  }

  .bannerContainerClasss {
    max-width: 280px;
    position: absolute;
    top: 50%;
    z-index: 9;
    right: 0;
    transform: translate(0%, -50%);
  }
}

.productCategory {
  padding-top: 80px;

  .category_locationSec {
    .main_heading {
      margin-bottom: 45px;
      padding-right: 70px;

      h1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        line-height: 30px;
        margin-bottom: 0px;
      }
    }

    .selectDropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 910px;
      padding: 10px 22px;
      gap: 10px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1019607843);
      background-color: #fff;
      position: relative;

      .selectValue {
        width: 25%;
        display: flex;
        align-items: center;

        :global(.form-select) {
          min-width: 190px;
          border: none;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        button {
          font-size: 16px;
          font-weight: 400;
          border-radius: 5px;
          background-color: #008cd3;
          height: 55px;
          min-width: 180px;
          border: none;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          color: #ffea00;
          font-size: 22px;
        }
      }
    }

    .bannerImage {
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }
  }

  .bannerSideImg {
    img {
      max-width: 100%;
      height: 590px;
    }
  }
}

.filter_topSlider {
  .slideMain_box {
    display: flex;
    justify-content: space-between;

    .left {
      width: 100%;

      .boxes {
        text-align: center;
        display: block;
        color: #716d6d;
        text-decoration: none;
        padding-bottom: 10px;
        transition: color 0.3s ease;
        position: relative;

        &:hover {
          &::before {
            background-color: #716d6d !important;
          }

          :global(img) {
            filter: opacity(1);
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 3px;
          background-color: #000;
          bottom: 2px;
          left: 25%;
          border-radius: 4px;
          transform: scaleX(0);
          transform-origin: bottom center;
          transition: transform 0.3s ease;
        }

        &:hover,
        &.active {
          color: #000000;

          &::before {
            transform: scaleX(1);
          }
        }

        svg {
          margin-bottom: 10px;
          font-size: 30px;
        }

        :global(img) {
          margin-bottom: 10px;
          filter: opacity(0.6);
          transition: 0.3s ease;
          object-fit: contain;
        }

        p {
          margin-bottom: 0px;
        }
      }

      :global(.owl-theme) {
        position: relative;

        :global(.owl-nav) {

          :global(.owl-prev),
          :global(.owl-next) {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -150%);
            border: 1px solid #000;
            border-radius: 50%;
            display: flex;
            padding: 3px !important;
          }

          :global(.owl-prev) {
            left: 0;
          }

          :global(.owl-next) {
            right: -30px;
          }

          :global(.disabled) {
            display: none !important;
          }
        }
      }
    }

    // .right {
    //     width: 9%;

    //     .filterBtn {

    //         text-align: end;

    //         :global(.btn) {
    //             border-radius: 20px;

    //             :global(svg) {
    //                 margin-right: 5px;
    //             }
    //         }
    //     }
    // }
  }
}

.rentList_Sides {
  .main_heading {
    h2 {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 14px;
      text-align: center;
    }
  }

  .see_allBtn {
    text-align: end;

    :global(a) {
      color: #ff0000;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.access_adsSec {
  .paddingRight {
    padding-right: 50px;
  }

  .main_heading {
    h2 {
      font-size: 35px;
      font-weight: 700;
    }
  }

  :global(.owl-carousel) {
    :global(.owl-item) {
      .quick_AccessBox {
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
        margin: 12px 0px;
        transition: 0.4s;

        &:hover {
          background-color: #008cd3;

          .contentBox {
            color: #fff;
          }
        }

        img {
          width: 108px;
          height: 85px;
          object-fit: cover;
        }

        .contentBox {
          padding-left: 18px;

          h4 {
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
            margin-bottom: 9px;
          }

          p {
            margin-bottom: 2px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
          }
        }
      }
    }
  }

  :global(.owl-carousel) {
    :global(.owl-item) {
      .ads_imgBox {
        margin: 12px 0px;
        margin-right: 4px;
        text-decoration: none;
        color: #000;

        img {
          height: 96px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

.suggestAddsSec_slider {
  h2 {
    font-size: 35px;
    font-weight: 600;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {

  .rentList_Sides {
    padding-top: 6px;

    .main_heading {
      h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
  }

  .suggestAddsSec_slider {
    h2 {
      font-size: 24px;
    }
  }

  // .banner_main {
  //   height: 90px;
  //   background-position: center;

  //   .banner_boxes {
  //     padding: 10px 35px;
  //     padding-bottom: 28px;
  //   }

  //   :global(.cardSet_forMobile) {
  //     background-color: #00c6ff;
  //     margin-top: 60px;
  //     height: 252px;
  //   }
  // }

  .boxes {
    p {
      margin-bottom: 0px;
      font-size: 12px;
    }
  }

  .lookingSection {
    .filterBox {
      display: block;

      .boxCard {
        margin-bottom: 10px;
      }

      .searchButton {
        :global(.btn) {
          min-width: 100%;
          margin-left: 0px;
        }
      }
    }
  }

  .paddingRight {
    padding-right: 10px;
  }

  .productCategory {
    padding-top: 20px;

    .category_locationSec {
      .main_heading {
        margin-bottom: 30px;
        padding-right: 0px;

        h1 {
          font-size: 32px;
          font-weight: 700;
          line-height: 45px;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
        }
      }

      .selectDropdown {
        width: 100%;
        display: block;

        .selectValue {
          width: 100%;
          margin-bottom: 10px;

          :global(.form-select) {
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          button {
            min-width: 100%;
          }
        }
      }

      .bannerImage {
        display: block;

        img {
          height: auto;
        }
      }
    }

    .bannerSideImg {
      img {
        height: auto;
      }
    }
  }

  .banner_main {
    :global(.owl-carousel) {

      :global(.owl-dots) {

        :global(.owl-dot) {
          :global(span) {
            width: 5px;
            height: 5px;
            margin: 4px 2px;
            opacity: 0.2;
            background: #4e4e4e;
          }

          &:global(.active) {
            :global(span) {
              opacity: .2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {

  .filter_topSlider {
    padding-top: 24px;

    .slideMain_box {
      .left {
        :global(.owl-theme) {
          :global(.owl-nav) {
            :global(.owl-next) {
              right: -20px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .banner_main {
    // height: 90px;
    // background-position: center;

    :global(.owl-carousel) {
      :global(.owl-item) {
        :global(.item) {
          :global(img) {
            height: 90px;
          }
        }
      }
    }

    .bannerContainerClasss {
      position: inherit;
      transform: none;
      left: inherit;
      top: inherit;
      max-width: 100%;
      width: 100%;
    }

    .banner_boxes {
      padding: 6px 35px;
      padding-bottom: 9px;

      .back_btn {
        :global(.btn) {
          width: 25px;
          height: 25px;
          margin-bottom: 7px;
        }
      }

      .banner_attach {
        padding: 5px 8px;

        .banner_FlexArea {
          .left {
            .img_box {
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 22px;
              }
            }

            .box_content {
              h4 {
                margin-bottom: 3px;
                font-size: 12px;
              }

              p {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    :global(.cardSet_forMobile) {
      background-color: #00c6ff;
      margin-top: 0px;
      height: 206px;
    }
  }

}

@media (max-width: 991px) {}

@media only screen and (min-width: 568px) and (max-width: 991px) {
  .banner_main {
    // height: 207px;
    // padding: 10px 0;
    // background-position: 54%;

    .banner_boxes {


      .banner_attach {
        padding: 4px 8px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0px;
        }

        .banner_FlexArea {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            align-items: center;

            .img_box {
              max-width: 40px;
              min-width: 40px;
              height: 40px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: #daeefd;
              border-radius: 50%;

              img {
                width: 22px;
              }
            }

            .box_content {
              margin-left: 14px;

              h4 {
                font-size: 15px;
              }

              p {
                margin-bottom: 0px;
                font-weight: 600;
                font-size: 13px;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                span {
                  background-color: rgb(10 10 201);
                  color: #fff;
                  padding: 0px 8px;
                  border-radius: 13px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  position: relative;
                  top: 1px;
                }
              }
            }
          }

          .right {
            padding-right: 3px;

            :global(svg) {
              font-size: 20px;
            }
          }
        }
      }
    }

    .another_banner_boxes {
      .banner_attach {
        padding: 4px 8px;
        margin-bottom: 4px;

        .banner_FlexArea {
          .left {
            .img_box {
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 22px;
              }
            }

            .box_content {
              h4 {
                font-size: 15px;
              }
            }
          }
        }
      }

      .back_btn {
        :global(.btn) {
          width: 22px;
          height: 22px;
          margin-bottom: 4px;
        }
      }

      .back_btn2 {
        :global(.btn) {
          width: 27px;
          height: 27px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #f3f5f8;
          padding: 4px;
          border-radius: 50%;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          margin-bottom: 0px;
          opacity: 0;

          &:active {
            border-color: transparent;
          }

          &:hover {
            :global(svg) {
              color: #0585c5;
            }
          }

          :global(svg) {
            font-size: 18px;
            color: #000;
            transition: 0.3s;
          }
        }
      }
    }

    :global(.owl-carousel) {
      :global(.owl-item) {
        :global(.item) {
          :global(img) {
            height: 215px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1150px) {
  .banner_main {
    // height: auto;

    :global(.owl-carousel) {
      :global(.owl-item) {
        :global(.item) {
          :global(img) {
            height: 286px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1439px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-12%, -50%);
    }
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1570px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-15%, -50%);
    }
  }
}

@media only screen and (min-width: 1571px) and (max-width: 1620px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-35%, -50%);
    }
  }
}

@media only screen and (min-width: 1621px) and (max-width: 1700px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-45%, -50%);
    }
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-55%, -50%);
    }
  }
}

@media only screen and (min-width: 1801px) and (max-width: 1900px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-85%, -50%);
    }
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2000px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-105%, -50%);
    }
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2200px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-125%, -50%);
    }
  }
}

@media only screen and (min-width: 2201px) and (max-width: 5200px) {
  .banner_main {
    .bannerContainerClasss {
      transform: translate(-165%, -50%);
    }
  }
}

// @media only screen and (min-width: 1600px) and (max-width: 2199px) {
//   .banner_main {
//     min-height: 570px;
//   }
// }
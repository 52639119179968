.topBanner {
    // background-color: #e5f3ff;
    background-image: url(../../../assets/images/banner-bg-main-new.png);
    padding: 130px 0px 60px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 190px 0px;
    position: relative;
    background-position: center;

    h1 {
        color: #000;
        font-size: 40px;
        margin-bottom: 0px;
        text-align: center;
        font-weight: 700;
    }
}

.contentPage {
    padding: 60px 0px;

    h2 {
        color: #008cd3;
        font-weight: 700;
        margin-bottom: 12px;
        font-size: 26px;
        padding-top: 20px;
    }

    h3 {
        color: #008cd3;
        font-weight: 700;
        margin-bottom: 12px;
        font-size: 21px;
        padding-top: 20px;
    }

    p {
        font-size: 15px;
        color: #5d6f7d;
        font-weight: 400;
        margin-bottom: 16px;
        line-height: inherit;
    }

    ul{
        li{
            font-size: 15px;
        color: #5d6f7d;
        font-weight: 400;
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {

    .topBanner {
        padding: 90px 0px 20px;
        background-position: right;

        h1 {
            font-size: 28px;
            text-align: center;
        }
    }

    .contentPage {
        padding: 10px 0px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}
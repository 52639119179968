.modalCts {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border: none;
      background-color: #fff;
      border-radius: 20px;

      :global(.modal-header) {
        background-image: linear-gradient(var(--Main2), var(--Main3));
        text-align: center;
        color: #000;
        padding: 15px 20px;
        border-radius: 20px 20px 0px 0px;

        :global(.modal-title) {
          text-align: left;
          width: 100%;
          font-size: 20px;
        }

        .closeIcon {
          padding: 0;
          border: none;
          width: 30px;
          height: 30px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          color: #787474;
          position: absolute;
          right: 10px;
          top: 16px;

          :global(svg) {
            font-size: 26px;
          }

          &.v2 {
            top: 8px;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }

      :global(.modal-body) {
        padding: 25px 25px;

        h3 {
          font-size: 24px;
          font-weight: 600;
          max-width: 330px;
          margin: 0 auto;
        }

        .filled_Input {
          .label_tag {
            margin-bottom: 4px;
            font-size: 15px;
            font-weight: 700;
            display: inline-block;
          }

          .input_tag {
            height: 48px;
            font-size: 16px;
            box-shadow: none;
            font-weight: 500;
            border: 1px solid #bbc2c6;
            border-radius: 4px;
            padding: 10px 12px;
            background: #f4f4f4;
          }
        }
      }

      :global(.modal-footer) {
        padding: 8px 12px;
        justify-content: center;

        :global(.btn) {
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          border: none;
          min-width: 150px;
          padding: 10px 15px;
          border-radius: 10px;

          &.actionBtn {
            background: #008cd3;
            color: #fff;
          }

          &.cancelBtn {
            color: #7f8699;
            background-color: #eff0f5;
          }
        }
      }
    }
  }
}

.ratingStar {
  padding: 0;
  list-style: none;
  margin-bottom: 0;

  .labelling {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 14px;
    text-align: center;
  }

  li {
    display: flex;
    justify-content: space-between;
    font-size: 32px;

    :global(svg) {
      color: #008cd3;
      cursor: pointer;
    }
  }
}

:global(.form-group) {
  :global(.form-control) {
    &::placeholder {
      color: #787474;
      font-size: 14px;
    }
  }
}

.add_postCards {
  border: 1px solid transparent;
  padding: 15px 22px;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
  position: relative;
  top: 0;
  transition: opacity 0.2s ease-in, top 0.2s ease-in;
  background: #dfdfdf;

  &:hover {
    top: -3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border: 1px solid #a3a4af;
  }

  .post_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    :global(svg) {
      font-size: 25px;
      color: #2d2d56;
    }
  }

  h4 {
    color: #2d2d56;
    font-weight: 600;
    margin: 16px 0px 16px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 11px;

    li {
      font-size: 15px;
      color: #000;
      margin-bottom: 12px;
      display: flex;
      align-items: baseline;
      gap: 6px;
      line-height: normal;

      span {
        :global(svg) {
          color: #178bdf;
        }
      }
    }
  }

  &.popularCards {
    background-color: #dae6f1;
    transform: scale(1.06);
  }

  &.active {
    border: 2px solid #6670fc;
  }
}

.getContact_modal {
  .profileBox {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 12px 0px;
    padding-top: 0;
    text-decoration: none;
    border-radius: 0px;

    .left {
      display: flex;
      align-items: center;
      border: 1px solid #7a7a7a;
      border-radius: 50%;
      overflow: hidden;

      img {
        border-radius: 9px;
        height: 80px;
        width: 80px;
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .modalAdd_Post {
    :global(.modal-body) {
      height: calc(-231px + 100vh);
      overflow: auto;
    }
  }
}
.wrapper {
    
    .pageContent {
        background-color: #fff;
        height: 100vh;
        max-width: 400px;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        margin: 0 auto;
    }
}
 

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/


@media (max-width:991px) {
    
    .wrapper {
    
        .pageContent {
            max-width: 100%;
        }
    }
     

}

@media only screen and (min-width:768px) and (max-width:991px) {
  
}
:root {

    --Main: #12477a;
    --Color: #1C1C1C;

    
    --BodyBackground:#f9f9f9;

    --WebsiteMain :#12477a;
}

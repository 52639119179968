.pageSpace {
  padding: 70px 0px;
}

.heart {
  button {
    border: none;
    background: none;
    padding: 0;
    display: inline-flex;
    width: max-content;
    align-items: center;
    gap: 7px;
    border: 1px solid #ffffff;
    background: #46464654;
    color: #000000;
    border-radius: 6px;
    padding: 8px 10px;

    :global(svg) {
      display: block;
      fill: rgba(255, 255, 255, 0);
      height: 18px;
      width: 18px;
      stroke: #ffffff !important;
      stroke-width: 40px;
      overflow: visible;
    }

    &:hover {
      :global(svg) {
        fill: rgb(255 0 0 / 65%);
      }
    }

    &.active {
      :global(svg) {
        fill: rgb(255 0 0 / 100%);
      }
    }
  }
}
.jobDetail_section {
  .heading_title {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 21px 32px;
    border: 1px solid #dad7d7;
    border-radius: 10px;
    background: #009ad7;

    .left_box {
      color: #fff;
      width: 100%;
      text-align: left;
      margin-right: 10px;

      h2 {
        font-weight: 600;
        font-size: 31px;
        margin-bottom: 4px;
      }

      h4 {
        font-size: 20px;
        font-weight: 600;
      }

      .locationPin {
        display: flex;
        align-items: center;
        color: #565353;
        color: #fff;
      }
    }

    .right_box {
      display: inline-flex;
      gap: 8px;

      .chat {
        :global(a) {
          display: inline-flex;
          border: 1px solid #008cd3;
          color: #fff;
          padding: 6px 22px;
          border-radius: 6px;
          font-size: 15px;
          text-decoration: none;
          background: #008cd3;
        }
      }
    }
  }

  .about_job {
    border: 1px solid #dad7d7;
    padding: 21px 32px;
    border-radius: 10px;
    margin-top: 45px;

    .job_detailsBox {
      h2 {
        font-size: 27px;
        font-weight: 600;
      }
      img {
        max-width: 100%;
      }

      ul {
        padding-left: 18px;
        li {
          margin-bottom: 3px;
        }
      }
    }
  }

  .about_salary {
    border: 1px solid #dad7d7;
    padding: 10px 21px;
    border-radius: 10px;
    background: #f2f2f2;
    margin-top: 45px;

    .avg_slary {
      h2 {
        font-size: 26px;
        font-weight: 600;
      }

      p {
        font-weight: 600;
        color: #545252;
        font-size: 13px;
      }
    }

    .type_employ {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0px;
      margin-top: 25px;

      li {
        margin-bottom: 13px;

        .type_listBox {
          display: flex;

          .list_icon {
            min-width: 45px;
            height: 45px;
            border: 1px solid #a4a1a2;
            background: #fbfafa;
            color: #000;
            text-align: center;
            line-height: 40px;
            border-radius: 50px;
            margin-right: 10px;
            font-size: 19px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          .type_describe {
            margin-top: 3px;
            h4 {
              font-size: 17px;
              font-weight: 600;
              margin-bottom: 1px;
            }
            p {
              color: #8c8c92;
              margin-bottom: 0px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
}

@media (max-width: 480px) {
  .jobDetail_section {
    .heading_title {
      flex-direction: column;
    }
  }
}
